import React, { useEffect, useState, useMemo } from 'react'
import { Table, Layout, Breadcrumb, Dropdown, Button, Modal } from 'antd'

import { getTableColumns, currentCollection, Capitalize } from '../helpers/Functions'
import { PagesEnum, ReportTypeEnums } from '../helpers/Enums'
import Icon from '@mdi/react'
import { mdiArrowDownDropCircle } from '@mdi/js'
// import SearchBar from '../components/SearchBar'
import AdvancedSearchBar from '../components/AdvancedSearchBar'
import ClientSidebar from '../components/ClientSidebar'
import DispatchSheet from '../components/DispatchSheet'
import DispatchEmail from '../components/DispatchEmails'
import moment from 'moment'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { dynamicTableSelector } from '../recoil/selectors'
import { DropdownMenu } from '../components/utility/Dropdown'
import OrderDetailsHeader from '../components/utility/OrderDetailsHeader'
import DynamicModal from '../components/DynamicModal'

import { useFetch, useLazyFetch } from '../hooks/useGraphql'
import { PageSizes, SegmentsEnum } from '../helpers/Enums'
import { useLocation } from 'react-router'
import Pagination from '../components/utility/Pagination'
import { Footer } from 'antd/lib/layout/layout'

const { Header, Content } = Layout

const DynamicTable = (props) => {
	// data state
	const { pathname } = useLocation()
	const collection = useMemo(() => currentCollection(pathname), [pathname])
	const [searchData, setSearchData] = useState({ data: [], collection: '' })
	const [dateRange, setDateRange] = useState([moment().subtract(10, 'days'), moment()])
	// optional display state
	const [modalData, setModalData] = useState({ visible: false, disabled: true })
	const [emailModal, setEmailModal] = useState(false)
	const [dispatchEmail, setDispatchEmail] = useState(false)
	const [showActiveOrders, setShowActiveOrders] = useState(true)
	const [reportType, setReportType] = useState()

	// selection state
	const [selectedClient, setSelectedClient] = useState({ businessCode: 'All Clients', _id: 'All Clients' })
	const [selectedRecord, setSelectedRecord] = useState()
	const [selectedTab, setSelectedTab] = useState('Import')
	const [selectedRows, setSelectedRows] = useState([])

	// order filteration variables
	const [keyword, setKeyword] = useState('')
	const [filterProperty, setFilterProperty] = useState('orderNumber')

	const [pageInfo, setPageInfo] = useState({ pageNo: 1, pageSize: 10 })
	const [backendPageInfo, setBackendPageInfo] = useState({ pageNo: 1, pageSize: PageSizes[pageInfo?.pageSize]?.backend })

	// query variables
	let variables = {
		type: Capitalize(collection),
		dateRange: dateRange,
		status: showActiveOrders
			? ['Order Pending', 'Order Confirmed', 'Partial Ongoing', 'Partial Completed', 'Order Ongoing']
			: ['Order Completed', 'Order Cancelled'],
	}
	let subscriptionVariables = {
		type: Capitalize(collection),
		status: showActiveOrders
			? ['Order Pending', 'Order Confirmed', 'Partial Ongoing', 'Partial Completed', 'Order Ongoing']
			: ['Order Completed', 'Order Cancelled'],
	}
	// query hooks
	const [advancedSearch, searchLoading] = useLazyFetch({ type: 'AdvancedSearch', atomKey: collection })

	const loading = useFetch({
		type: 'Orders',
		atomKey: collection,
		variables: variables,
		subscriptionVariables: subscriptionVariables,
		pageInfo: backendPageInfo,
	})

	const { collectionData, totalCount } = useRecoilValue(
		dynamicTableSelector({ collection, selectedClient, showActiveOrders, selectedRecord, selectedTab, pageInfo, keyword })
	)

	useEffect(() => {
		setSelectedRows(collectionData?.filter((order) => selectedRows?.find((data) => data?._id === order?._id)) ?? [])
	}, [collectionData]) // eslint-disable-line

	const setSearchAtom = useSetRecoilState(SegmentsEnum[Capitalize(collection)].searchAtom)

	useEffect(() => {
		setBackendPageInfo({ pageNo: 1, pageSize: PageSizes[pageInfo.pageSize].backend })
	}, [pageInfo.pageSize]) // eslint-disable-line

	useEffect(() => resetState(), [collection]) // eslint-disable-line

	useEffect(() => {
		selectedRecord && setSelectedTab(Object.keys(selectedRecord?.movements)?.[0])
	}, [selectedRecord]) // eslint-disable-line

	useEffect(() => {
		keyword === '' && setSearchAtom(null)
	}, [keyword]) // eslint-disable-line

	const onCloseModal = () => {
		setEmailModal(false)
	}
	const onDispatchEmails = () => {
		setDispatchEmail(true)
	}
	const resetState = () => {
		setSelectedClient({ businessCode: 'All Clients', _id: 'All Clients' })
		setShowActiveOrders(true)
		setSelectedRecord()
		setSelectedRows([])
		setSearchAtom(null)
		setPageInfo({ pageNo: 1, pageSize: 10 })
		setBackendPageInfo({ pageNo: 1, pageSize: 50 })
	}

	return (
		<Layout className='FullHeight'>
			{!selectedRecord && <ClientSidebar {...props} selectedClient={selectedClient} setSelectedClient={setSelectedClient}></ClientSidebar>}
			<Layout>
				<Header className='FlexRowSpace GHBackground PaddingLeftRight HalfMarginTopBottom'>
					<div className='FlexRow'>
						<Breadcrumb separator='>' className='BoldFont FontSize16 HalfMarginRight'>
							<Breadcrumb.Item
								className={`${selectedRecord && 'PrimaryColor CursorPointer'}`}
								onClick={() => {
									setSelectedRecord()
									setSelectedRows([])
								}}
							>
								{showActiveOrders ? 'Active Orders' : 'Completed Orders'}
							</Breadcrumb.Item>
							{selectedRecord?.orderNumber && <Breadcrumb.Item>{`${PagesEnum[collection]?.initial} - ${selectedRecord?.orderNumber}`}</Breadcrumb.Item>}
						</Breadcrumb>
						{!selectedRecord && (
							<Dropdown overlay={DropdownMenu(showActiveOrders, setShowActiveOrders)} trigger={['click']} className='CursorPointer'>
								<Icon path={mdiArrowDownDropCircle} size={1} className='BlackColor CursorPointer' />
							</Dropdown>
						)}
					</div>
					<div className='flex flex-row items-center'>
						<Dropdown
							className={`flex items-center justify-center px-3 rounded h-[40px] w-44  mr-4 ${
								(selectedRecord === undefined && selectedRows.length !== 1) || (selectedRecord !== undefined && selectedRows.length >= 0)
									? 'cursor-not-allowed bg-[#d9d9d9] text-[#a3a3a3]'
									: 'cursor-pointer bg-primary-800 text-white'
							} `}
							trigger={['click']}
							// We have to disable the button if the number of selected parent orders is not equal to 1 and incase when a child order is selected
							disabled={(selectedRecord === undefined && selectedRows.length !== 1) || (selectedRecord !== undefined && selectedRows.length >= 0)}
							menu={{
								items: ReportTypeEnums[Capitalize(collection)],
								onClick: (e) => {
									setReportType(e.key)
									setEmailModal(true)
								},
							}}
						>
							<div className='flex justify-between'>
								<span className='font-black'>Generate Report</span>
								<Icon path={mdiArrowDownDropCircle} size={0.9} />
							</div>
						</Dropdown>
						<DispatchSheet
							data={{
								collection: collection,
								orders: selectedRecord ? { selectedRecord, selectedRows } : selectedRows,
								type: selectedRecord ? 'detailed' : 'bundled',
							}}
						/>
					</div>
				</Header>
				<Header className='GHBackground PaddingLeftRight'>
					{selectedRecord ? (
						<div className='FullWidth FlexColumn WBackground' style={{ borderRadius: '8px 8px 0px 0px' }}>
							{OrderDetailsHeader(collection, selectedRecord, setSelectedTab)}
						</div>
					) : (
						<div className='FullWidth FlexRowSpace WBackground' style={{ borderRadius: '8px 8px 0px 0px' }}>
							<AdvancedSearchBar
								selectedRecord={selectedRecord}
								data={collectionData}
								collection={collection}
								segment={collection}
								setSearchData={setSearchData}
								filterProperty={filterProperty}
								setFilterProperty={setFilterProperty}
								keyword={keyword}
								setKeyword={setKeyword}
								placeholder='Search'
								border={false}
								dateRange={dateRange}
								setDateRange={setDateRange}
								resetState={resetState}
							/>
							{keyword && (
								<Button
									className='DashedButton WhiteColor HalfMargin'
									onClick={() => {
										setPageInfo({ pageNo: 1, pageSize: 10 })
										setBackendPageInfo({ pageNo: 1, pageSize: 50 })
										advancedSearch({
											variables: {
												where: {
													ParentOrder: {
														[`Parent${Capitalize(collection)}`]: {
															...(filterProperty === 'documentNumber' ? { documentNumber_CONTAINS: keyword } : {}),
															// container number
															...(filterProperty === 'containerNumber'
																? {
																		[`Sub${Capitalize(collection)}s_SOME`]: {
																			containerNumber_CONTAINS: keyword,
																		},
																  }
																: {}),
															...(filterProperty === 'orderNumber' ? { orderNumber: parseInt(keyword) } : {}),
														},
														...(['driverPhone', 'driverVehicleRegistration', 'biltyNumber', 'jobNumber'].includes(filterProperty)
															? {
																	Movements_SOME: {
																		Job: {
																			...(filterProperty === 'driverPhone'
																				? {
																						Driver: {
																							phoneNumber_CONTAINS: keyword,
																						},
																				  }
																				: {}),
																			...(filterProperty === 'driverVehicleRegistration'
																				? {
																						Vehicle: {
																							registrationNumber_CONTAINS: keyword,
																						},
																				  }
																				: {}),
																			...(filterProperty === 'biltyNumber'
																				? {
																						bilty_SOME: {
																							biltyNumber_CONTAINS: keyword,
																						},
																				  }
																				: {}),
																			...(filterProperty === 'jobNumber' ? { jobNumber: parseInt(keyword) } : {}),
																		},
																	},
															  }
															: {}),
													},
												},
											},
										})
									}}
								>
									Click for Advanced Search
								</Button>
							)}
						</div>
					)}
				</Header>
				<Content className='PaddingLeftRight '>
					<>
						<Table
							columns={
								getTableColumns(setSelectedRecord, selectedRecord, modalData, setModalData, selectedTab)?.[collection]?.[
									`${selectedRecord ? 'detailedView' : 'groupedView'}`
								]
							}
							dataSource={selectedRecord ? collectionData : searchData?.data}
							rowKey={(record) => {
								return record?._id || record?.subOrders?.[0]?.subOrderNumber
							}}
							rowSelection={{
								selectedRows,
								onChange: (_, selectedRows) => setSelectedRows(selectedRows),
							}}
							pagination={false}
							scroll={selectedRecord ? { x: 3000, y: 'calc(100% - 61px)' } : { y: 'calc(100% - 61px)' }}
							className={!selectedRecord ? 'MainTable' : 'SecondaryTable'}
							size='small'
							bordered
							loading={loading || searchLoading}
						/>
					</>
				</Content>
				{!selectedRecord && (
					<Footer className='FooterPadding'>
						<Pagination setPageInfo={setPageInfo} setBackendPageInfo={setBackendPageInfo} totalCount={totalCount} pageInfo={pageInfo}></Pagination>
					</Footer>
				)}
			</Layout>
			<Modal
				width={1366}
				centered
				bodyStyle={{ overflowY: 'scroll', height: '700' }}
				className='and-modal-zero-padding'
				title='Dispatch Emails'
				visible={emailModal}
				onOk={onDispatchEmails}
				onCancel={onCloseModal}
				okText='Send Email'
				okButtonProps={{ disabled: modalData.disabled, loading: modalData.loading }}
			>
				<DispatchEmail
					collection={collection}
					reportType={reportType}
					selectedRows={selectedRows}
					dispatchEmail={dispatchEmail}
					setDispatchEmail={setDispatchEmail}
					setEmailModal={setEmailModal}
					setModalData={setModalData}
				/>
			</Modal>
			<DynamicModal modalData={modalData} setModalData={setModalData} segment={collection} />
		</Layout>
	)
}

export default DynamicTable

import { Dropdown, Menu } from 'antd'
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined, EllipsisOutlined } from '@ant-design/icons'
import Icon from '@mdi/react'
import { mdiChevronUp } from '@mdi/js'
import { PageSizes } from '../../helpers/Enums'
import { usePagination, LeftDOTS, RightDOTS } from '../../hooks/usePagination'
import { useState } from 'react'

const Pagination = (props) => {
	const { setPageInfo, setBackendPageInfo, totalCount, pageInfo } = props
	const [mouseEnter, setMouseEnter] = useState(null)
	const DropdownMenu = (
		<Menu
			className='NoMargin NoPadding LeftAlign BorderRadiusOverFlow'
			onClick={(event) => {
				setPageInfo({ pageNo: 1, pageSize: parseInt(event?.key) })
			}}
		>
			{Object.keys(PageSizes).map((size) => {
				return (
					<Menu.Item className={pageInfo.pageSize === parseInt(size) && 'SelectedDropDown'} key={size}>
						{size} / Page
					</Menu.Item>
				)
			})}
		</Menu>
	)

	const paginationRange = usePagination({ currentPage: pageInfo?.pageNo, totalCount, siblingCount: 1, pageSize: pageInfo?.pageSize })

	let lastPage = paginationRange[paginationRange?.length - 1]
	const firstPageIndex = 1
	return (
		<div className='FlexRowBox CursorPointer'>
			<div
				className={`PaginationItemArrow ${pageInfo.pageNo === firstPageIndex && `Disabled`}`}
				onClick={() => {
					pageInfo?.pageNo > firstPageIndex && setPageInfo((prev) => ({ ...prev, pageNo: prev?.pageNo - 1 }))
				}}
			>
				<LeftOutlined />
			</div>
			{paginationRange.map((pageNumber) => {
				// prettier-ignore
				if ([LeftDOTS].includes(pageNumber)) {
					return (
						<div
							className='PaginationItemDots'
							onMouseEnter={() => setMouseEnter(LeftDOTS)}
							onMouseLeave={() => setMouseEnter(null)}
							onClick={() => {
								setPageInfo((prev) => ({ ...prev, pageNo: pageInfo.pageNo - 5 < firstPageIndex ? firstPageIndex : pageInfo.pageNo - 5 }))
							}}
						>
							{mouseEnter === LeftDOTS ? <DoubleLeftOutlined style={{ fontSize: 'small' }} /> : <EllipsisOutlined />}
						</div>
					)
				}
				if ([RightDOTS].includes(pageNumber)) {
					return (
						<div
							className='PaginationItemDots'
							onMouseEnter={() => setMouseEnter(RightDOTS)}
							onMouseLeave={() => setMouseEnter(null)}
							onClick={() => {
								setPageInfo((prev) => ({ ...prev, pageNo: pageInfo.pageNo + 5 > lastPage ? lastPage : pageInfo.pageNo + 5 }))
							}}
						>
							{mouseEnter === RightDOTS ? <DoubleRightOutlined style={{ fontSize: 'small' }} /> : <EllipsisOutlined />}
						</div>
					)
				}
				return (
					<div
						className={`PaginationItem ${pageInfo.pageNo === pageNumber && `Selected`}`}
						onClick={() => setPageInfo((prev) => ({ ...prev, pageNo: pageNumber }))}
					>
						{pageNumber}
					</div>
				)
			})}
			<div
				className='PaginationItemArrow'
				onClick={() => {
					// prettier-ignore
					(pageInfo?.pageNo === lastPage || !lastPage) && setBackendPageInfo((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));

					pageInfo?.pageNo === lastPage
						? setPageInfo((prev) => ({ ...prev, pageNo: lastPage }))
						: !lastPage
						? setPageInfo((prev) => ({ ...prev, pageNo: 1 }))
						: setPageInfo((prev) => ({ ...prev, pageNo: prev?.pageNo + 1 }))
				}}
			>
				<RightOutlined />
			</div>
			<Dropdown setPageInfo={setPageInfo} overlay={DropdownMenu} trigger={['click']} className='CursorPointer HalfMarginLeft QuarterPadding CenterAlign'>
				<div className='FlexRow PHBackground HalfPadding'>
					<p className='BoldFont HalfMarginLeftRight'>{pageInfo?.pageSize.toString() + ' / Page'}</p>
					<Icon path={mdiChevronUp} size={1} className='PrimaryColor' />
				</div>
			</Dropdown>
		</div>
	)
}

export default Pagination

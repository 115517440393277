import { Modal, Form, Button, Comment, Avatar } from 'antd'
import { RenderSchema } from '../helpers/FormRenderer'
import { CommentBoxSchema } from '../helpers/FormSchemas'
import { Capitalize, ModalHeader } from '../helpers/Functions'
import { UserOutlined } from '@ant-design/icons'
import { userAtom } from '../recoil/atoms'
import { useRecoilValue } from 'recoil'

import moment from 'moment'
import { useMutate } from '../hooks/useGraphql'
import { GraphQLEnums } from '../helpers/Enums'

const DynamicModal = (props) => {
	const { visible, type, data } = props.modalData // eslint-disable-line
	const { segment } = props
	const [form] = Form.useForm()
	const user = useRecoilValue(userAtom)
	const [createComment, commentLoading] = useMutate({ type: 'Comments', action: 'create' })
	// console.log(data)
	const SaveComment = async (values) => {
		const NewComment = [
			{
				comment: values?.comment,
				Author: {
					connect: {
						where: {
							node: {
								_id: user?._id,
							},
						},
					},
				},
				[`Parent${Capitalize(segment)}`]: {
					connect: {
						where: {
							node: {
								_id: data?.parentId,
							},
						},
					},
				},
			},
		]
		createComment({ variables: { [`${GraphQLEnums.Comments?.create?.createKey}`]: NewComment } })
		onCancel()
	}

	const onCancel = () => {
		form.resetFields()
		props.setModalData((prevState) => {
			return { ...prevState, visible: false }
		})
	}

	const ModalFooter = (
		<div className='FullWidth'>
			<Button className='Button SecondaryButton' onClick={onCancel}>
				CANCEL
			</Button>
			{type === 'CommentThread' && (
				<Button className='Button PrimaryButton' onClick={() => form.submit()} loading={commentLoading}>
					SUBMIT
				</Button>
			)}
		</div>
	)

	const ModalTypes = {
		CommentThread: {
			modalSize: 'RectangleModal',
			title: 'Comments',
			schema: RenderSchema(CommentBoxSchema({ documentId: data?.[0]?._id })),
			finishFunction: SaveComment,
		},
		BreakDownComment: {
			modalSize: 'RectangleModal',
			title: 'Comments',
		},
	}

	return (
		<Modal
			visible={visible}
			title={ModalHeader(ModalTypes[type]?.title, ModalTypes[type]?.description)}
			closable={false}
			className={ModalTypes[type]?.modalSize}
			footer={ModalFooter}
			centered={true}
		>
			{type === 'CommentThread' &&
				data?.comments?.map((comment, index) => (
					<Comment
						key={index}
						author={<p className='BoldFont FontSize14 BlackColor'>{`${comment?.Author?.firstName} ${comment?.Author?.lastName}`}</p>}
						avatar={<Avatar icon={<UserOutlined />} />}
						content={<p>{comment?.comment}</p>}
						datetime={
							<span className='GrayColor'>
								{moment(comment?.createdAt).format('DD-MM-YYYY hh:mm A')} ({moment(comment?.createdAt).fromNow()})
							</span>
						}
					/>
				))}
			{type === 'BreakDownComment' && data?.type === 'breakdown' && data?.comment && (
				<Comment
					key={data?.time}
					author={<p className='BoldFont FontSize14 BlackColor'>{`${data?.CreatedBy?.firstName} ${data?.CreatedBy?.lastName}`}</p>} //`
					avatar={<Avatar icon={<UserOutlined />} />}
					content={<p>{data?.comment}</p>}
					datetime={
						<span className='GrayColor'>
							{moment(data?.createdAt).format('DD-MM-YYYY hh:mm A')} ({moment(data?.createdAt).fromNow()})
						</span>
					}
				/>
			)}
			{type === 'CommentThread' && (
				<Form key='ModalForm' layout='vertical' form={form} onFinish={ModalTypes[type]?.finishFunction || onCancel} scrollToFirstError>
					{ModalTypes[type]?.schema}
				</Form>
			)}
		</Modal>
	)
}

export default DynamicModal

import { Layout } from 'antd'
import Icon from '@mdi/react'
// import firebase from 'firebase/compat/app'
import { mdiRestart, mdiAlertCircle } from '@mdi/js'
import { useEffect } from 'react/cjs/react.production.min'
// import { useEffect, useMemo } from 'react'
import { Loader } from '../../helpers/Functions'
const { Content } = Layout

const TableErrorBoundary = ({ error, componentStack, resetErrorBoundary }) => {
	console.log('*************************************************************************************')
	console.log(error?.networkError?.result?.errors?.[0]?.extensions?.code)
	console.log(error?.networkError?.result?.errors?.[0]?.extensions?.exception?.stacktrace?.join('\n'))
	console.log('*************************************************************************************')
	console.log({ error })
	useEffect(() => {
		if (
			error.message === 'Unauthenticated' ||
			error?.graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED' ||
			error?.networkError?.result?.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED'
		) {
			resetErrorBoundary()
		}
	}, [500]) // eslint-disable-line
	return (
		<Content className='bg-white rounded-b-lg pb-2'>
			{error.message === 'Unauthenticated' ||
			error?.graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED' ||
			error?.networkError?.result?.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED' ? (
				<div className='flex flex-col justify-center items-center h-full'>
					<div className='my-auto align-middle'>
						<Loader />
					</div>
				</div>
			) : (
				<div className='flex flex-col justify-center items-center h-full'>
					<Icon path={mdiAlertCircle} size={7} color='#dd2222' className='mx-auto' />
					<div className='my-6 text-xl font-bold text-center flex-wrap w-[300px] mx-auto'>{error.message}</div>
					<div
						className='my-2 cursor-pointer btn-primary flex flex-row items-center text-lg w-[150px] h-[60px] mx-auto pl-[10] justify-center'
						onClick={async () => {
							// const token = await
							// localStorage.setItem('token', JSON.stringify({token}))
							resetErrorBoundary()
						}}
					>
						<Icon className=' mr-2' path={mdiRestart} color='#ffffff' size={2} />
						<div className='text-xl'>Retry</div>
					</div>
				</div>
			)}
		</Content>
	)
}

export default TableErrorBoundary

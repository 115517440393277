import moment from 'moment'
import { ContainerDimensionsEnum, SegmentsEnum } from '../../helpers/Enums'
import { formatPhoneNumber, twoDigit } from '../../helpers/Functions'

/* ============================================================================================= */
/* ======================================== Info Columns ======================================= */
/* ============================================================================================= */

const DataRow = ({ title, value }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				height: 50,
				width: '33.33%',
			}}
		>
			<span style={{ color: '#33333', textAlign: 'start', fontSize: '12px', fontWeight: 500 }}>{title}:</span>
			<span
				style={{
					color: '#33333',
					textAlign: 'start',
					fontSize: '12px',
					fontWeight: 700,
					marginLeft: '4px',
				}}
			>
				{value}
			</span>
		</div>
	)
}

export const CommodityRow = {
	key: 'CommodityRow',
	title: 'COMMODITY',
	render: ({ props: { freightType } }) => <DataRow title='Commodity' value={freightType || 'N/A'} />,
}

export const ShippingLineRow = {
	key: 'ShippingLineRow',
	title: 'Shipping Line (S.L)',
	render: ({ props: { shippingLine } }) => <DataRow title='Shipping Line (S.L)' value={shippingLine} />,
}

export const ReturnDeadlineClientRow = {
	key: 'ReturnDeadlineClientRow',
	title: 'Empty Return Deadline - Client',
	render: ({ props: { committedReturnDeadline } }) => {
		return (
			<DataRow title='Empty Return Deadline - Client' value={committedReturnDeadline ? moment(committedReturnDeadline)?.format('DD-MM-YYYY') : 'N/A'} />
		)
	},
}

export const ReturnDeadlineSLRow = {
	key: 'ReturnDeadlineSLRow',
	title: 'Empty Return Deadline - S.L',
	render: ({ props: { freeDays } }) => {
		return <DataRow title='Empty Return Deadline - S.L' value={freeDays ? moment(freeDays)?.format('DD-MM-YYYY') : 'N/A'} />
	},
}

export const TotalLoadedWeightRow = {
	key: 'TotalLoadedWeightRow',
	title: 'Total Loaded Weight',
	render: ({ props: { totalWeight } }) => <DataRow title='Total Loaded Weight' value={`${totalWeight?.toFixed(2)} Tonnes`} />,
}

export const TotalLoadedBalesRow = {
	key: 'TotalLoadedBalesRow',
	title: 'Total No. of Units',
	render: ({ props: { totalNumberOfBales } }) => <DataRow title='Total No. of Units' value={totalNumberOfBales} />,
}

export const ReleaseTerminalRow = {
	key: 'ReleaseTerminalRow',
	title: 'Container Release Terminal',
	render: ({ props: { releaseTerminal } }) => {
		return <DataRow title='Container Release Terminal' value={releaseTerminal?.[0]?.Location?.formattedAddress} />
	},
}

export const CommittedDeliveryDateRow = {
	key: 'CommittedDeliveryDateRow',
	title: 'Client Committed Delivery Date',
	render: ({ props: { clientCommittedDeliveryDate } }) => {
		return (
			<DataRow title='Committed Delivery Date' value={clientCommittedDeliveryDate ? moment(clientCommittedDeliveryDate)?.format('DD-MM-YYYY') : 'N/A'} />
		)
	},
}

export const VesselCutoffDateRow = {
	key: 'VesselCutoffDateRow',
	title: 'Vessel CutOff Date',
	render: ({ props: { vesselCutoffDT } }) => {
		return <DataRow title='Vessel CutOff Date' value={vesselCutoffDT ? moment(vesselCutoffDT)?.format('DD-MM-YYYY') : 'N/A'} />
	},
}

/* ============================================================================================= */
/* ==================================== Body Table Columns ===================================== */
/* ============================================================================================= */

export const VehicleNumberColumn = {
	key: 'VehicleNumberColumn',
	title: 'VEHICLE NO.',
	render: ({ movement }) => <div style={{ fontSize: '12px', fontWeight: 700 }}>{movement?.job?.Vehicle?.registrationNumber?.toUpperCase()}</div>,
}

export const JobNumberColumn = {
	key: 'JobNumberColumn',
	title: 'JOB NO.',
	render: ({ movement }) => <div style={{ fontSize: '12px', fontWeight: 500 }}>J-{movement?.job?.jobNumber}</div>,
}

export const ContainerNumberColumn = {
	key: 'ContainerNumberColumn',
	title: 'CONTAINER NO.',
	render: ({ movement }) => (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
			{movement?.subOrders?.map((subOrder) => (
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{subOrder?.containerInfo?.containerNumber || 'N/A'}</div>
			))}
		</div>
	),
}

export const ContainerDimensionColumn = {
	key: 'ContainerDimensionColumn',
	title: 'CNT. DIMENSIONS',
	render: ({ movement }) => (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
			{movement?.subOrders?.map((subOrder) => (
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{ContainerDimensionsEnum[subOrder?.containerInfo?.dimension]?.text}</div>
			))}
		</div>
	),
}

export const BiltyNumberColumn = {
	key: 'BiltyNumberColumn',
	title: 'BILTY NO.',
	render: ({ movement, segment, documentNumber }) => {
		const bilty = movement?.job?.bilty?.filter((bilty) => (segment === 'Import' ? bilty?.documentNumber === documentNumber : true))
		return (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
				{bilty?.map((bilty) =>
					!(bilty?.halfDoneBiltyDocument?.[0] || bilty?.biltyDocument?.[0]) ? (
						<div style={{ fontSize: '12px', fontWeight: 500 }}>{bilty?.biltyNumber || 'N/A'}</div>
					) : (
						<a
							style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}
							target='_blank'
							rel='noreferrer'
							href={bilty?.halfDoneBiltyDocument?.[0] || bilty?.biltyDocument?.[0]}
						>
							<div style={{ fontSize: '12px', fontWeight: 500, color: '#1C75BC', textDecoration: 'underline' }}>{bilty?.biltyNumber}</div>
							<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
								<path
									d='M6.66667 0C2.99333 0 0 2.99333 0 6.66667C0 10.34 2.99333 13.3333 6.66667 13.3333C10.34 13.3333 13.3333 10.34 13.3333 6.66667C13.3333 2.99333 10.34 0 6.66667 0ZM6 5.33333V2.66667H7.33333V5.33333H9.33333L6.66667 8L4 5.33333H6ZM10 10H3.33333V8.66667H10V10Z'
									fill='#1C75BC'
								/>
							</svg>
						</a>
					)
				)}
			</div>
		)
	},
}

export const DriverPhoneNumberColumn = {
	key: 'DriverPhoneNumberColumn',
	title: 'DRIVER PHONE NO.',
	render: ({ movement }) => <div style={{ fontSize: '12px', fontWeight: 500 }}>{formatPhoneNumber(movement?.job?.Driver?.phoneNumber)}</div>,
}

export const StatusColumn = {
	key: 'StatusColumn',
	title: 'STATUS',
	render: ({ movement, segment }) => {
		const driverStatus =
			SegmentsEnum?.[segment]?.trackingEnum?.[
				movement?.job?.dummy?.[0]?.status === 'firstContact' || movement?.job?.dummy?.[0]?.status === undefined
					? 'vehicleAssigned'
					: movement?.job?.dummy?.[0]?.status
			]
		return (
			<div
				style={{
					fontSize: '12px',
					fontWeight: 700,
					padding: '4px 8px',
					width: 'fit-content',
					backgroundColor: '#F2F2F2',
					color: `${driverStatus?.color}`,
					textAlign: 'center',
					borderRadius: '100px',
				}}
			>
				{driverStatus?.long?.toUpperCase()}
			</div>
		)
	},
}

export const LeftPortAtColumn = {
	key: 'LeftPortAtColumn',
	title: 'LEFT PORT AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const leftLoadingAt = movement?.job?.dummy?.find((statusHistory) => statusHistory?.status === 'driverMovingToDropoffLocation')?.timestamp
		return leftLoadingAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(leftLoadingAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(leftLoadingAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const LoadedDepartedAtColumn = {
	key: 'LoadedDepartedAtColumn',
	title: 'LOADED & DEPARTED AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const leftLoadingAt = movement?.job?.dummy?.find((statusHistory) => statusHistory?.status === 'driverMovingToDropoffLocation')?.timestamp
		return leftLoadingAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(leftLoadingAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(leftLoadingAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const ReachedPickupAtColumn = {
	key: 'ReachedPickupAtColumn',
	title: 'REACHED PICKUP AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const reachedLoadingAt = movement?.job?.dummy?.find((statusHistory) => statusHistory?.status === 'driverReachedPickupLocation')?.timestamp
		return reachedLoadingAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(reachedLoadingAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(reachedLoadingAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const ReachedDropoffAtColumn = {
	key: 'ReachedDropoffAtColumn',
	title: 'REACHED DROPOFF AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const reachedDropoffAt = movement?.job?.dummy?.find((statusHistory) => statusHistory?.status === 'driverReachedDropoffLocation')?.timestamp
		return reachedDropoffAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(reachedDropoffAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(reachedDropoffAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const UnloadingCompletedAtColumn = {
	key: 'UnloadingCompletedAtColumn',
	title: 'UNLOADING COMPLETED AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const unloadingCompletedAt = movement?.job?.dummy
			?.find((statusHistory) => statusHistory?.status === 'driverReachedDropoffLocation')
			?.DriverStatusJourneys?.find((journey) => journey?.status === 'unloadingCompleted')?.timestamp
		return unloadingCompletedAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(unloadingCompletedAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(unloadingCompletedAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const EmptyReturnColumn = {
	key: 'EmptyReturnColumn',
	title: 'EMPTY RETURN LOCATION',
	render: ({ movement }) => (
		<div style={{ fontSize: '12px', fontWeight: 500 }}>
			{movement?.subOrders?.[0]?.orderLocations?.find((location) => location?.locationType === 'Empty Container Dropoff')?.Location?.formattedAddress}
		</div>
	),
}

export const JobMovedBalesColumn = {
	key: 'JobMovedBalesColumn',
	title: 'NO. OF UNITS',
	render: ({ movement }) => (
		<div style={{ fontSize: '12px', fontWeight: 500 }}>{movement?.subOrders?.reduce((acc, subOrder) => acc + subOrder?.movementCount, 0)}</div>
	),
}

export const LoadedWeightColumn = {
	key: 'LoadedWeightColumn',
	title: 'LOADED WEIGHT',
	render: ({ movement, segment, documentNumber }) => {
		const bilty = movement?.job?.bilty?.filter((bilty) => (segment === 'Import' ? bilty?.documentNumber === documentNumber : true))
		return (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
				{bilty?.map((bilty) =>
					!bilty?.initialKantaDocument?.[0] ? (
						<div style={{ fontSize: '12px', fontWeight: 500 }}>{bilty?.initialKantaWeight || 'N/A'}</div>
					) : (
						<a
							style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}
							target='_blank'
							rel='noreferrer'
							href={bilty?.initialKantaDocument?.[0]}
						>
							<div style={{ fontSize: '12px', fontWeight: 500, color: '#1C75BC', textDecoration: 'underline' }}>{bilty?.initialKantaWeight || 'N/A'}</div>
							<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
								<path
									d='M6.66667 0C2.99333 0 0 2.99333 0 6.66667C0 10.34 2.99333 13.3333 6.66667 13.3333C10.34 13.3333 13.3333 10.34 13.3333 6.66667C13.3333 2.99333 10.34 0 6.66667 0ZM6 5.33333V2.66667H7.33333V5.33333H9.33333L6.66667 8L4 5.33333H6ZM10 10H3.33333V8.66667H10V10Z'
									fill='#1C75BC'
								/>
							</svg>
						</a>
					)
				)}
			</div>
		)
	},
}

/* ============================================================================================= */
/* =================================== Summary Table Columns =================================== */
/* ============================================================================================= */

export const TotalWeightMovedColumn = {
	key: 'TotalWeightMovedColumn',
	title: 'TOTAL WEIGHT MOVED',
	render: ({ props: { processedMovements } }) => {
		const movedWeight = processedMovements?.reduce(
			(acc, movement) => acc + movement?.subOrders?.reduce((acc, subOrder) => acc + parseFloat(subOrder?.shipmentInfo?.freightWeight), 0),
			0
		)
		return <div style={{ fontSize: '15px', fontWeight: 600 }}>{twoDigit(movedWeight?.toFixed(2))} Tonnes</div>
	},
}

export const RemainingWeightColumn = {
	key: 'RemainingWeightColumn',
	title: 'REMAINING WEIGHT',
	render: ({ props: { shipmentInfo, processedMovements } }) => {
		const movedWeight = processedMovements?.reduce(
			(acc, movement) => acc + movement?.subOrders?.reduce((acc, subOrder) => acc + parseFloat(subOrder?.shipmentInfo?.freightWeight || '0'), 0),
			0
		)
		return <div style={{ fontSize: '15px', fontWeight: 600 }}>{twoDigit((parseFloat(shipmentInfo?.freightWeight) - movedWeight)?.toFixed(2))} Tonnes</div>
	},
}

export const LotUnitsMovedColumn = {
	key: 'LotUnitsMovedColumn',
	title: 'UNITS MOVED',
	render: ({ props: { processedMovements } }) => {
		const jobBales = processedMovements?.reduce(
			(acc, movement) => acc + movement?.subOrders?.reduce((acc, subOrder) => acc + parseFloat(subOrder?.shipmentInfo?.freightCount || '0'), 0),
			0
		)

		return <div style={{ fontSize: '15px', fontWeight: 600 }}>{twoDigit(jobBales)}</div>
	},
}

export const TotalContainersColumn = {
	key: 'TotalContainersColumn',
	title: 'TOTAL CONTAINERS',
	render: ({ props: { containerSpecifics } }) => (
		<>
			{Object.entries(containerSpecifics)?.map(([key, value]) => (
				<div style={{ fontSize: '15px', fontWeight: 600, marginBottom: '6px' }}>
					{twoDigit(value)} x {ContainerDimensionsEnum[key]?.text}
				</div>
			))}
		</>
	),
}

export const BalesMovedColumn = {
	key: 'UnitsMovedColumn',
	title: 'TOTAL UNITS MOVED',
	render: ({ props: { processedMovements } }) => {
		const jobBales = processedMovements?.reduce(
			(acc, movement) => acc + movement?.subOrders?.reduce((acc, subOrder) => acc + subOrder?.movementCount, 0),
			0
		)
		return <div style={{ fontSize: '15px', fontWeight: 600 }}>{twoDigit(jobBales)}</div>
	},
}

export const RemainingBalesColumn = {
	key: 'RemainingBalesColumn',
	title: 'REMAINING UNITS',
	render: ({ props: { processedMovements, totalNumberOfBales } }) => {
		const jobBales = processedMovements?.reduce(
			(acc, movement) => acc + movement?.subOrders?.reduce((acc, subOrder) => acc + subOrder?.movementCount, 0),
			0
		)
		return <div style={{ fontSize: '15px', fontWeight: 600 }}>{twoDigit(totalNumberOfBales - jobBales)}</div>
	},
}

export const TotalVehiclesColumn = {
	key: 'TotalVehiclesColumn',
	title: 'TOTAL VEHICLES',
	render: ({ props: { numberOfVehicles } }) => {
		return <div style={{ fontSize: '15px', fontWeight: 600 }}>{twoDigit(numberOfVehicles)}</div>
	},
}

export const VehiclesAssignedColumn = {
	key: 'VehiclesAssignedColumn',
	title: 'VEHICLES ASSIGNED',
	render: ({ props: { processedMovements } }) => <div style={{ fontSize: '15px', fontWeight: 600 }}>{twoDigit(processedMovements?.length)}</div>,
}

export const PendingAssignmentColumn = {
	key: 'PendingAssignmentColumn',
	title: 'PENDING ASSIGNMENT',
	render: ({ props: { numberOfVehicles, processedMovements } }) => {
		const pendingVehicles = numberOfVehicles - processedMovements?.length
		return <div style={{ fontSize: '15px', fontWeight: 600 }}>{pendingVehicles >= 0 ? twoDigit(pendingVehicles) : 'N/A'}</div>
	},
}

/* ============================================================================================= */
/* ==================================== Expanded Row Chips ===================================== */
/* ============================================================================================= */

export const LastLocationChip = {
	key: 'LastLocationChip',
	render: ({ movement, segment }) => {
		const lastSubStatus = movement?.job?.dummy?.[0]?.DriverStatusJourneys?.filter(
			(journey) => journey?.type === 'location' || journey?.type === 'subStatus'
		)?.[0]

		const lastSubStatusUpdate =
			lastSubStatus?.type === 'subStatus' ? SegmentsEnum?.[segment]?.trackingEnum?.substatus[lastSubStatus?.status]?.long : lastSubStatus?.status

		return (
			<div
				style={{
					background: '#E5E5E5',
					padding: '3px 8px',
					borderRadius: '100px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginLeft: '20px',
					width: 'fit-content',
				}}
			>
				<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
					<path
						d='M3.7915 10.2082L8.1665 8.1665L10.2082 3.7915L5.83317 5.83317L3.7915 10.2082ZM6.99984 7.58317C6.83456 7.58317 6.69602 7.52727 6.58421 7.41546C6.47241 7.30366 6.4165 7.16511 6.4165 6.99984C6.4165 6.83456 6.47241 6.69602 6.58421 6.58421C6.69602 6.47241 6.83456 6.4165 6.99984 6.4165C7.16511 6.4165 7.30366 6.47241 7.41546 6.58421C7.52727 6.69602 7.58317 6.83456 7.58317 6.99984C7.58317 7.16511 7.52727 7.30366 7.41546 7.41546C7.30366 7.52727 7.16511 7.58317 6.99984 7.58317ZM6.99984 12.8332C6.19289 12.8332 5.43456 12.68 4.72484 12.3738C4.01511 12.0675 3.39775 11.6519 2.87275 11.1269C2.34775 10.6019 1.93213 9.98456 1.62588 9.27484C1.31963 8.56511 1.1665 7.80678 1.1665 6.99984C1.1665 6.19289 1.31963 5.43456 1.62588 4.72484C1.93213 4.01511 2.34775 3.39775 2.87275 2.87275C3.39775 2.34775 4.01511 1.93213 4.72484 1.62588C5.43456 1.31963 6.19289 1.1665 6.99984 1.1665C7.80678 1.1665 8.56511 1.31963 9.27484 1.62588C9.98456 1.93213 10.6019 2.34775 11.1269 2.87275C11.6519 3.39775 12.0675 4.01511 12.3738 4.72484C12.68 5.43456 12.8332 6.19289 12.8332 6.99984C12.8332 7.80678 12.68 8.56511 12.3738 9.27484C12.0675 9.98456 11.6519 10.6019 11.1269 11.1269C10.6019 11.6519 9.98456 12.0675 9.27484 12.3738C8.56511 12.68 7.80678 12.8332 6.99984 12.8332ZM6.99984 11.6665C8.30261 11.6665 9.40609 11.2144 10.3103 10.3103C11.2144 9.40609 11.6665 8.30261 11.6665 6.99984C11.6665 5.69706 11.2144 4.59359 10.3103 3.68942C9.40609 2.78525 8.30261 2.33317 6.99984 2.33317C5.69706 2.33317 4.59359 2.78525 3.68942 3.68942C2.78525 4.59359 2.33317 5.69706 2.33317 6.99984C2.33317 8.30261 2.78525 9.40609 3.68942 10.3103C4.59359 11.2144 5.69706 11.6665 6.99984 11.6665Z'
						fill='#3C7D8B'
					/>
				</svg>
				<div style={{ fontSize: '12px', fontWeight: 500, lineHeight: 'normal', color: '#676767', marginLeft: '4px' }}>
					{lastSubStatusUpdate || 'To Be Updated'}
				</div>
			</div>
		)
	},
}

export const LastLocationTimeChip = {
	key: 'LastLocationTimeChip',
	render: ({ movement }) => {
		const lastLocationTime = movement?.job?.dummy?.[0]?.DriverStatusJourneys?.filter(
			(journey) => journey?.type === 'location' || journey?.type === 'subStatus'
		)?.[0]?.timestamp

		return (
			<div
				style={{
					background: '#E5E5E5',
					padding: '3px 8px',
					borderRadius: '100px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginLeft: '6px',
					width: 'fit-content',
				}}
			>
				<svg
					style={{ alignSelf: 'center', width: '12px', height: '12px' }}
					xmlns='http://www.w3.org/2000/svg'
					width='12'
					height='12'
					viewBox='0 0 12 12'
					fill='none'
				>
					<path
						d='M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM6 10.8C3.354 10.8 1.2 8.646 1.2 6C1.2 3.354 3.354 1.2 6 1.2C8.646 1.2 10.8 3.354 10.8 6C10.8 8.646 8.646 10.8 6 10.8ZM6.3 3H5.4V6.6L8.52 8.52L9 7.74L6.3 6.12V3Z'
						fill='#3C7D8B'
					/>
				</svg>
				<div style={{ fontSize: '12px', fontWeight: 500, lineHeight: 'normal', color: '#676767', marginLeft: '4px' }}>
					{lastLocationTime ? moment(lastLocationTime)?.format('HH:mm, DD-MM-YY') : '--:--'}
				</div>
			</div>
		)
	},
}

import { Layout, Menu, Dropdown } from 'antd'
import Icon from '@mdi/react'
import { mdiMenu, mdiAccountCircleOutline, mdiChevronDown } from '@mdi/js'
import { PagesEnum } from '../helpers/Enums'
import { useLogIn } from '../hooks/useAuth'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../recoil/atoms'

const Header = (props) => {
	const { setCollapseMenu, currentPage } = props
	const user = useRecoilValue(userAtom)
	const { logout } = useLogIn()
	const DropdownMenu = (
		<Menu className='ProfileMenu BoldFont NoMargin NoPadding'>
			<Menu.Item key='1'>Profile Info</Menu.Item>
			<Menu.Item key='2' danger onClick={logout}>
				Sign Out
			</Menu.Item>
		</Menu>
	)
	return (
		<>
			<Layout.Header className='PrimaryHeader FlexRowSpace PaddingLeftRight BorderBottom HideOnMobile'>
				<div className='FlexRow'>
					<Icon path={mdiMenu} size={1} className='PrimaryColor CursorPointer' onClick={() => setCollapseMenu((prevState) => !prevState)} />
					<p className='BoldFont FontSize16 MarginLeft'>{PagesEnum[currentPage]?.long}</p>
					{/* {ProjectId !== 'bridgelinx-client' && <PortalPill />} */}
				</div>
				<Dropdown overlay={DropdownMenu} trigger={['click']} className='CursorPointer'>
					<div className='ProfileInfo FlexRow PHBackground HalfPadding'>
						<Icon path={mdiAccountCircleOutline} size={1} className='PrimaryColor CursorPointer' />
						<p className='BoldFont FontSize16 HalfMarginLeftRight'>{user?.firstName + ' ' + user?.lastName}</p>
						<Icon path={mdiChevronDown} size={1} className='PrimaryColor CursorPointer' />
					</div>
				</Dropdown>
			</Layout.Header>
		</>
	)
}

export default Header

import ReportLayoutEnums from './ReportLayoutEnums'

const ReportFooter = (props) => {
	const { orderCategory, segment, reportType } = props

	return (
		<>
			<div
				style={{ display: 'flex', height: 60, width: '100%', color: '#004AAD', padding: '0 20px', alignItems: 'center', fontSize: '14px', fontWeight: 700 }}
			>
				REPORT SUMMARY
			</div>
			<table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: 0 }}>
				<thead>
					<tr style={{ height: '62px', background: '#F2F2F2' }}>
						{ReportLayoutEnums[segment][orderCategory][reportType]?.summary?.map((column, index) => (
							<th
								style={{
									...(ReportLayoutEnums[segment][orderCategory][reportType]?.summary?.length - 1 !== index && { width: '150px' }),
									textAlign: 'left',
									padding: '20px 10px 20px 20px',
									fontSize: '11px',
								}}
							>
								{column.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr style={{ height: '67px' }}>
						{ReportLayoutEnums[segment][orderCategory][reportType]?.summary?.map((column) => (
							<td style={{ padding: '20px 10px 20px 20px' }}>{column.render({ props })}</td>
						))}
					</tr>
				</tbody>
			</table>
		</>
	)
}

export default ReportFooter

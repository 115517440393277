import moment from 'moment'
import { ContainerDimensionsEnum, SegmentsEnum } from '../../helpers/Enums'
import { formatPhoneNumber } from '../../helpers/Functions'

/* ============================================================================================= */
/* ==================================== Body Table Columns ===================================== */
/* ============================================================================================= */

export const VehicleAndPhoneColumn = {
	key: 'VehicleAndPhoneColumn',
	title: 'VEHICLE & DRIVER INFO',
	render: ({ movement }) => (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
			<div style={{ fontSize: '12px', fontWeight: 700 }}>{movement?.job?.Vehicle?.registrationNumber?.toUpperCase()}</div>
			<div style={{ fontSize: '12px', fontWeight: 500, color: '#676767' }}>{formatPhoneNumber(movement?.job?.Driver?.phoneNumber)}</div>
		</div>
	),
}

export const ContainerAndDimensionColumn = {
	key: 'ContainerAndDimensionColumn',
	title: 'CONTAINER DETAILS',
	render: ({ movement }) => (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{movement?.subOrders?.map((subOrder) => (
				<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '6px', gap: '4px' }}>
					<div style={{ fontSize: '12px', fontWeight: 700 }}>{subOrder?.containerInfo?.containerNumber || 'N/A'}</div>
					<div style={{ fontSize: '12px', fontWeight: 500 }}>{ContainerDimensionsEnum[subOrder?.containerInfo?.dimension]?.text}</div>
				</div>
			))}
		</div>
	),
}

export const StatusAndTimeColumn = {
	key: 'StatusAndTimeColumn',
	title: 'STATUS',
	render: ({ movement, segment }) => {
		const driverStatus =
			SegmentsEnum?.[segment]?.trackingEnum?.[
				movement?.job?.dummy?.[0]?.status === 'firstContact' || movement?.job?.dummy?.[0]?.status === undefined
					? 'vehicleAssigned'
					: movement?.job?.dummy?.[0]?.status
			]
		const driverStatusTime = movement?.job?.dummy?.[0]?.timestamp ? moment(movement?.job?.dummy?.[0]?.timestamp)?.format('HH:mm, DD-MM-YY') : '--:--'
		return (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
				<div
					style={{
						width: 'fit-content',
						fontSize: '12px',
						fontWeight: 700,
						padding: '4px 12px',
						backgroundColor: '#F2F2F2',
						color: `${driverStatus?.color}`,
						textAlign: 'center',
						borderRadius: '100px',
					}}
				>
					{driverStatus?.long?.toUpperCase()}
				</div>
				<div
					style={{
						width: 'fit-content',
						fontSize: '11px',
						fontWeight: 600,
						padding: '2px 8px',
						backgroundColor: '#F2F2F2',
						color: '#676767',
						textAlign: 'center',
						borderRadius: '100px',
					}}
				>
					{driverStatusTime}
				</div>
			</div>
		)
	},
}

export const ReachedEmptyAtColumn = {
	key: 'ReachedEmptyAtColumn',
	title: 'REACHED EMPTY AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const reachedEmptyAt = movement?.job?.dummy?.find((statusHistory) => statusHistory?.status === 'driverReachedContainerDropoffLocation')?.timestamp
		return reachedEmptyAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(reachedEmptyAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(reachedEmptyAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const EmptyReturnedAtColumn = {
	key: 'EmptyReturnedAtColumn',
	title: 'EMPTY RETURNED AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const emptyReturnedAt = movement?.job?.dummy
			?.find((statusHistory) => statusHistory?.status === 'driverReachedContainerDropoffLocation')
			?.DriverStatusJourneys?.find((journey) => journey?.status === 'unloadingCompleted')?.timestamp
		return emptyReturnedAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(emptyReturnedAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(emptyReturnedAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const ReachedContainerTerminalAt = {
	key: 'ReachedContainerTerminalAt',
	title: 'REACHED CONT. TERMINAL AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const reachedContainerTerminalAt = movement?.job?.dummy?.find(
			(statusHistory) => statusHistory?.status === 'driverReachedContainerTerminal'
		)?.timestamp
		return reachedContainerTerminalAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(reachedContainerTerminalAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(reachedContainerTerminalAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

export const LeftContainerTerminalAt = {
	key: 'LeftContainerTerminalAt',
	title: 'LEFT CONT. TERMINAL AT',
	widthClassname: '110px',
	render: ({ movement }) => {
		const leftContainerTerminalAt = movement?.job?.dummy?.find((statusHistory) => statusHistory?.status === 'driverMovingToPickupLocation')?.timestamp
		return leftContainerTerminalAt ? (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<div style={{ fontSize: '12px', fontWeight: 700 }}>{moment(leftContainerTerminalAt)?.format('DD-MM-YY')}</div>
				<div style={{ fontSize: '12px', fontWeight: 500 }}>{moment(leftContainerTerminalAt)?.format('HH:mm')}</div>
			</div>
		) : (
			<div style={{ fontSize: '12px', fontWeight: 500 }}>--</div>
		)
	},
}

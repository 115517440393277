import { useEffect } from 'react'
import { Capitalize } from '../helpers/Functions'
import ReportBody from './ReportBody'
import ReportFooter from './ReportFooter'
import ReportHeader from './ReportHeader'
import ReportLayoutEnums from './ReportLayoutEnums'

const GenerateReport = (props) => {
	const { selectedRows, collection, reportType, setModalData } = props

	const selectedOrder = selectedRows[0]
	const orderCategory = selectedOrder?.orderCategory
	const { movements } = selectedOrder
	const newMovements = {}
	const segment = Capitalize(collection)

	delete Object.assign(newMovements, movements)['ImportShortHaul']
	let processedMovements = newMovements?.[segment]
		? newMovements?.[segment]
		: Object.values(newMovements)?.length > 0 &&
		  Object.values(newMovements).reduce(
				(movements, movement) => ({
					...movements,
					...movement,
					undefined: { job: movement.undefined?.job, subOrders: [...(movements?.undefined?.subOrders || []), ...(movement?.undefined?.subOrders || [])] },
				}),
				{}
		  )
	processedMovements = Object.values(processedMovements)?.filter((movement) => movement?._id)

	useEffect(() => {
		setModalData((prevState) => ({ ...prevState, disabled: processedMovements?.length <= 0 }))
	}, [selectedRows]) //eslint-disable-line

	return (
		<div style={{ overflowY: 'scroll', height: 550 }}>
			<div id={'Report'} style={{ height: '100%', width: '100%', fontFamily: 'Inter, sans-serif', margin: '0px !important', padding: '0px !important' }}>
				<ReportHeader {...{ ...selectedOrder, processedMovements, segment, reportType }} />
				<ReportBody {...{ ...selectedOrder, processedMovements, segment, reportType }} />
				{ReportLayoutEnums[segment][orderCategory][reportType]?.summary?.length !== 0 && (
					<ReportFooter {...{ ...selectedOrder, processedMovements, segment, reportType }} />
				)}
			</div>
		</div>
	)
}

export default GenerateReport

import { Tooltip, Steps, Dropdown, Menu } from 'antd'
import { StatusPill, ReadableString, Capitalize, SubOrderNoPill } from '../helpers/Functions'
import { PagesEnum, ContainerDimensionsEnum, DoStatuses, orderCategories, OrderStatusEnum } from '../helpers/Enums'
import { VehicleArranged } from '../components/utility/OrderDetailsHeader'
import moment from 'moment'
import Icon from '@mdi/react'
import { mdiCalendar, mdiClock, mdiCommentPlusOutline, mdiCommentQuoteOutline, mdiDotsVertical, mdiRayStartArrow, mdiVectorLink } from '@mdi/js'

/* ============================================================================================== */
/* ==================================== GROUPED TABLE COLUMNS =================================== */
/* ============================================================================================== */
export const GroupedOrderDetailsColumn = (props) => {
	const { collection, modalData, setModalData } = props
	return {
		title: 'Order',
		key: 'gorder',
		width: 150,
		defaultSortOrder: 'descend',
		sortDirections: ['ascend', 'descend', 'ascend'],
		sorter: (a, b) => a?.orderNumber - b?.orderNumber,
		render: (_, record) => {
			const { orderNumber, comments, orderCategory, refNumber, orderStatus } = record
			return (
				<div className='FlexColumn'>
					<div className='FlexRow'>
						<Tooltip placement='right' color='#0066CC' title='View Details' onClick={() => props.setSelectedRecord(record)}>
							<span className='BoldFont PrimaryColor CursorPointer'>
								{PagesEnum[collection].initial}-{orderNumber}
							</span>
						</Tooltip>
						{comments?.length > 0 && (
							<Icon
								path={mdiCommentQuoteOutline}
								size={0.8}
								className='PrimaryColor QuarterMarginLeft CursorPointer'
								onClick={() =>
									setModalData({
										...modalData,
										visible: true,
										type: 'CommentThread',
										data: record,
									})
								}
							/>
						)}
					</div>
					<p className='QuarterPaddingTop BoldFont'>{refNumber && `Ref #: ${refNumber}`}</p>
					{orderCategories.hasOwnProperty(orderCategory) && (
						<p
							className={`${orderCategories[orderCategory] && orderCategories[orderCategory].color} Capitalize StatusPill`}
							style={{ backgroundColor: `${orderCategories[orderCategory].backgroundColor}` }}
						>
							{orderCategories[orderCategory].text}
						</p>
					)}
					{OrderStatusEnum.hasOwnProperty(orderStatus) && (
						<p
							className={`Capitalize StatusPill`}
							style={{ color: `${OrderStatusEnum[orderStatus]?.color}`, backgroundColor: ` ${OrderStatusEnum[orderStatus]?.background}` }}
						>
							{OrderStatusEnum[orderStatus].text}
						</p>
					)}
				</div>
			)
		},
	}
}

export const ClientName = {
	title: 'Client',
	key: 'clientName',
	width: 150,
	render: (_, record) => record?.businessCode,
}

export const DocumentNumber = (collection) => ({
	title: collection === 'import' ? 'BL Number' : 'CRO Number',
	key: 'documentNumber',
	render: (_, record) => record?.documentNumber,
})

export const LoadingDate = {
	title: 'Dates',
	key: 'dates',
	sortDirections: ['ascend', 'descend', 'ascend'],
	sorter: (a, b) => {
		if (a?.biltyDT === b?.biltyDT) {
			return 0
		} else if (a?.biltyDT === '') {
			return 1
		} else if (b?.biltyDT === '') {
			return -1
		} else {
			return moment(b?.biltyDT)?.diff(moment(a?.biltyDT), 'days')
		}
	},
	width: 150,
	render: (_, record) => {
		return (
			<div>
				{record?.loadingDT && <p className='FontSize12 GrayColor'>Loading Date</p>}
				{record?.loadingDT && <p className='GrayColor BoldFont'>{moment(record?.loadingDT).format('DD-MMM-YYYY')}</p>}
				{record?.biltyDT && (
					<>
						<p className='FontSize12 GrayColor'>Bilty Date</p>
						<p className='GrayColor BoldFont'>{record?.biltyDT}</p>
					</>
				)}
			</div>
		)
	},
}

export const BiltyDate = {
	title: 'Bilty',
	key: 'bilty',
	sorter: (a, b) => {
		return parseInt(b?.job?.bilty?.[0]?.biltyCreatedAt || 0) - parseInt(a?.job?.bilty?.[0]?.biltyCreatedAt || 0)
	},
	defaultSortOrder: 'descend',
	sortDirections: ['ascend', 'descend', 'ascend'],
	width: 150,
	render: (_, record) => {
		const bilties = record?.job?.bilty?.filter((bilty) =>
			record?.type === 'Import' ? bilty?.documentNumber === record?.subOrders?.[0]?.documentNumber : true
		)
		return (
			<>
				{bilties?.map((bilty) => {
					const link = bilty?.halfDoneBiltyDocument?.[0] || bilty?.biltyDocument?.[0]
					return (
						<div>
							{bilty?.halfDoneBiltyDocument || bilty?.biltyDocument ? (
								<a href={link} target='_blank' rel='noreferrer'>
									<p className='BoldFont PrimaryColor Link'>{bilty?.biltyNumber}</p>
								</a>
							) : bilty?.biltyCreatedAt ? (
								<p className='BoldFont'>{bilty?.biltyNumber}</p>
							) : (
								<div className='text-sm font-bold text-red-400 underline underline-offset-[3px] mb-1'>Bilty Not Created</div>
							)}
							{bilty?.biltyCreatedAt && <p className='GrayColor BoldFont'>{moment(bilty?.biltyCreatedAt, 'x').format('DD-MMM-YYYY')}</p>}
						</div>
					)
				})}
			</>
		)
	},
}

export const ShippingLine = {
	title: 'Shipping Line',
	key: 'shippingLine',
	render: (_, record) => record?.shippingLine,
}

export const VehiclesArranged = {
	title: 'Vehicles Arranged',
	key: 'vehiclesArranged',
	width: 250,
	render: (_, record) => VehicleArranged(record, true),
}

export const DoStatus = {
	title: 'Delivery Order Status',
	key: 'doStatus',
	render: (_, record) =>
		DoStatuses.hasOwnProperty(record?.doStatus) ? (
			<p
				className='WhiteColor StatusPill'
				style={{
					height: 24,
					backgroundColor: DoStatuses[record.doStatus]?.background,
				}}
			>
				{DoStatuses[record.doStatus]?.text}
			</p>
		) : (
			<p className='BoldFont FontSize12 ErrorColor'>Information not available.</p>
		),
}
export const SubOrderDoStatus = {
	title: 'Delivery Order Status',
	key: 'subOrderDoStatus',
	width: 250,
	render: (_, record) => {
		// helping
		return DoStatuses.hasOwnProperty(record?.subOrders?.[0]?.doStatus) ? (
			<p
				className='WhiteColor StatusPill'
				style={{
					height: 24,
					backgroundColor: DoStatuses[record?.subOrders?.[0]?.doStatus]?.background,
				}}
			>
				{DoStatuses[record?.subOrders?.[0]?.doStatus]?.text}
			</p>
		) : (
			<p className='BoldFont FontSize12 ErrorColor'>Information not available.</p>
		)
	},
}

export const Dropoff = {
	title: 'Dropoff',
	key: 'Dropoff',
	ellipsis: true,
	render: (_, record) => {
		const dropoff = record?.dropoff?.[0]
		return <p>{`${dropoff?.additionalDetails}${dropoff?.additionalDetails === '' ? dropoff?.additionalDetails : ','} ${dropoff?.formattedAddress}`}</p>
	},
}

export const Route = {
	title: 'Route',
	key: 'route',
	render: (_, record) => {
		const loading = record?.loading?.[0]
		const dropoff = record?.dropoff?.[record?.dropoff?.length - 1]
		return (
			<div className='FlexRow'>
				<p className='HalfWidth LineLimit1'>{loading?.formattedAddress}</p>
				<Icon path={mdiRayStartArrow} size={1} className='PrimaryColor HalfMarginLeftRight' />
				<p className='HalfWidth LineLimit1'>{dropoff?.formattedAddress}</p>
			</div>
		)
	},
}

export const Options = ({ modalData, setModalData }) => ({
	key: 'options',
	width: 60,
	render: (_, record) => (
		<Dropdown overlay={dotsDropdownMenu({ record, modalData, setModalData })} trigger={['click']} className='CursorPointer'>
			<Icon path={mdiDotsVertical} size={1} className='BlackColor HalfMargin CursorPointer' />
		</Dropdown>
	),
})

/* ============================================================================================== */
/* =================================== EXPANDED TABLE COLUMNS =================================== */
/* ============================================================================================== */
export const ExpandedOrderDetailsColumn = {
	title: 'Order',
	key: 'order',
	sorter: (a, b) => {
		return parseInt(b?._id || 0) - parseInt(a?._id || 0)
	},
	defaultSortOrder: 'descend',
	sortDirections: ['ascend', 'descend', 'ascend'],
	fixed: 'left',
	width: 200,
	render: (_, record) => {
		return (
			<div className='HalfPadding'>
				<div className='FlexRow FlexWrap'>
					{record?.subOrders?.map((subOrder) => {
						return <>{SubOrderNoPill(subOrder?.type, subOrder?.subOrderNumber, record?.job?.jobNumber)}</>
					})}
				</div>
				{record?.job?.jobNumber && <p className='PrimaryColor BoldFont QuarterPadding'>{`J# ${record?.job?.jobNumber}`}</p>}
				{StatusPill(record?.subOrders?.[0]?.orderStatus?.status)}
			</div>
		)
	},
}

export const ContainerDetails = {
	title: 'Container Details',
	key: 'containerDetails',
	width: 200,
	render: (_, record) => (
		<div>{record?.subOrders?.map((subOrder) => showContainerInformation({ record: subOrder, data: record, subOrderNumber: false }))}</div>
	),
}

export const LinkedContainers = (selectedRecord) => ({
	title: 'Linked Containers',
	key: 'linkedContainers',
	width: 600,
	render: (_, record) => {
		const linkedImportOrders = record?.linkedImportOrders?.map((linkedImportOrderId) =>
			selectedRecord?.subOrders?.find((importOrder) => importOrder._id === linkedImportOrderId)
		)
		return (
			<div className='ContentWidth FlexRow OverflowScroll'>
				{linkedImportOrders &&
					linkedImportOrders.length > 0 &&
					linkedImportOrders?.map((order) => showContainerInformation({ record: order, data: record, subOrderNumber: true }))}
			</div>
		)
	},
})

export const DriverDetails = {
	title: 'Driver Details',
	key: 'driverDetails',
	width: 200,
	render: (_, record) => {
		return record?.job?.Driver?.phoneNumber || record?.job?.Vehicle?.registrationNumber ? (
			<div className=''>
				<p>{record?.job?.Driver?.phoneNumber}</p>
				<p className='ContentWidth BoldFont HalfPaddingLeftRight GrayShadeColor GHBackground BorderRadius'>{record?.job?.Vehicle?.registrationNumber}</p>
			</div>
		) : (
			<p className='BoldFont FontSize12 ErrorColor'>No Driver Assigned.</p>
		)
	},
}

export const TrackingStatus = (props) => {
	const { modalData, setModalData } = props
	return {
		title: 'Tracking Status',
		key: 'trackingStatus',
		fixed: 'right',
		width: 275,

		render: (_, record) => {
			const movStatus =
				(record?.job?.Journeys &&
					Object.values(record?.job?.Journeys)
						.map((item) => item?.DriverStatusHistories)
						.flat()) ||
				[]

			const { lastStatus, lastSubStatus, lastLegitSubStatus } = getDriverLastStatus(movStatus)
			const ShowStatus = (status) => {
				return (
					<div className='FlexRowSpace'>
						<div>
							<p className='Capitalize'>{ReadableString(status?.status || status?.formattedAddress)}</p>
							<div className='FlexRow FontSize12'>
								<Icon path={mdiCalendar} size={0.8} className='QuarterMarginRight' />
								{status?.timestamp && moment(status?.timestamp).format('DD-MMM-YYYY HH:mm:ss')}
							</div>
						</div>
						{status?.type === 'breakdown' && (
							<Icon
								path={mdiCommentQuoteOutline}
								size={0.8}
								className='PrimaryColor QuarterMarginLeft CursorPointer'
								onClick={() =>
									setModalData({
										...modalData,
										visible: true,
										type: 'BreakDownComment',
										data: status,
									})
								}
							/>
						)}
					</div>
				)
			}

			return (
				<>
					<p className='BoldFont PrimaryColor Capitalize'>{ReadableString(lastStatus?.status?.replace('driver', ''))}</p>
					<p className='BoldFont PrimaryColor Capitalize'>{lastStatus?.timestamp && moment(lastStatus?.timestamp).format('DD-MMM-YY HH:mm')}</p>
					{lastSubStatus && (
						<div className={`${lastSubStatus?.type === 'breakdown' && 'ErrorColor EHBackground QuarterPadding BorderRadius'}`}>
							{ShowStatus(lastSubStatus)}
							{/* <p>{moment(lastSubStatus?.timestamp).format('DD-MMM-YY HH:mm')}</p> */}
						</div>
					)}
					{lastLegitSubStatus && (
						<>
							<p className='BoldFont FontSize12 QuarterMarginTop'>Last Known Status:</p>
							{ShowStatus(lastLegitSubStatus)}
						</>
					)}
				</>
			)
		},
	}
}

const StepDescription = (status) => {
	return (
		<div className={'Flex FlexColumn HalfPaddingTop'}>
			<div className={'HorizontalFlexAlign FlexRow CenterAlign'}>
				<Icon className={'Flex QuarterPaddingRight'} path={mdiCalendar} size={1} />
				<div className={'Flex'}>{status?.time && moment(status?.time).format(`DD - MMM - YYYY`)}</div>
			</div>
			<div className={'HorizontalFlexAlign FlexRow'}>
				<Icon className={'Flex QuarterPaddingRight'} path={mdiClock} size={1} />
				<div className={'Flex'}>{status?.time && moment(status?.time).format(`LT`)}</div>
			</div>
		</div>
	)
}

export const RouteProgress = (collection) => ({
	title: 'Route Progress',
	key: 'routeProgress',
	render: (_, record) => {
		const stepsData = getStepsData(collection, record)
		return (
			<Steps current={stepsData?.currentStep} progressDot>
				{stepsData?.timeline?.map((status) => (
					<Steps.Step key={status?.title} title={status?.title} description={status?.time && StepDescription(status)} />
				))}
			</Steps>
		)
	},
})

/* ============================================================================================== */
/* ============================== TABLE COLUMNS UTILITY FUNCTIONS =============================== */
/* ============================================================================================== */
export const getStepsData = (collection, record) => {
	const timeline = [
		{
			key: 'driverAssignedAt',
			title: 'Assigned by Supply',
			time: '',
		},
	]

	const loadings = record.subOrders.map((subOrder) => {
		return subOrder?.orderLocations
			?.filter((orderLocation) => {
				return orderLocation.locationType === 'loading'
			})
			?.sort((a, b) => a.steps - b.steps)
	})[0]

	const dropoffs = record.subOrders.map((subOrder) => {
		return subOrder?.orderLocations
			?.filter((orderLocation) => {
				return orderLocation.locationType === 'dropoff'
			})
			?.sort((a, b) => a.steps - b.steps)
	})[0]

	let driverPhoneNumber = record?.job?.Driver?.phoneNumber
	let vehicleRegNumber = record?.job?.Vehicle?.registrationNumber

	// error handling by adding the empty list in case the lists are output as undefined (in case of no journies)

	const pickupList = record?.job?.driverStatusHistories?.pickup || ['']
	const dropoffList = record?.job?.driverStatusHistories?.dropoff || ['']
	const containerList = record?.job?.driverStatusHistories?.container || ['']

	const driverStatusHistories = [...pickupList, ...dropoffList, ...containerList]

	// concats all the lists into one list

	// initially a single journey's statuses were used, thus the empty container dropoff wasnt being detected.
	// this allows us to combine all the statuses from all journies of a single order into one to be used later for
	// the route progress

	// to improve this functionality, in case any error persists, add the removeDuplicates() (made already) on above lists
	// keeping in mind the different cases of import & export (multiple dropoffs in import so do not remove dups from there)

	createLocationTimeline({
		collection,
		timeline,
		locations: loadings,
		type: 'loading',
		driverStatusHistory: driverStatusHistories,
	})
	createLocationTimeline({
		collection,
		timeline,
		locations: dropoffs,
		type: 'dropoff',
		driverStatusHistory: driverStatusHistories,
	})
	addConstantTimeline(collection, timeline, driverStatusHistories)

	let currentStep = 1
	if (timeline?.[timeline.length - 1]?.time) {
		currentStep = timeline.length
	} else if (driverPhoneNumber && vehicleRegNumber) {
		const lastKnownTime = timeline
			?.slice()
			?.reverse()
			?.findIndex((milestone) => milestone?.time !== undefined)

		if (lastKnownTime > 0) {
			currentStep = timeline.length - lastKnownTime
		}
	} else {
		currentStep = 0
	}

	return { timeline: timeline, currentStep: currentStep - 1 }

	// outdated initial code

	// createLocationTimeline(collection, timeline, loadings, 'loading', record?.driverStatusHistory)
	// createLocationTimeline(collection, timeline, dropoffs, 'dropoff', record?.driverStatusHistory)
	// addConstantTimeline(collection, timeline, record?.driverStatusHistory)
	// let currentStep = 1
	// if (timeline?.[timeline.length - 1]?.time) {
	// 	currentStep = timeline.length
	// } else if (record?.job?.Driver?.phoneNumber && record?.job?.Vehicle?.registrationNumber) {
	// 	const lastKnownTime = timeline
	// 		?.slice(1)
	// 		?.reverse()
	// 		?.findIndex((milestone) => milestone?.time !== undefined)
	// 	if (lastKnownTime > 0) {
	// 		currentStep = timeline.length - lastKnownTime
	// 	}
	// } else {
	// 	currentStep = 0
	// }
	// return { timeline: timeline, currentStep: currentStep - 1 }
}

// export const createTimeline = (timeline, list, type, collection)=> {

// 	list.map((each)=>{

// 		let titleText = ''
// 		titleText += (type === 'pickup' && collection === 'import') || (type === 'dropoff' && collection === 'export') ? 'Port' : Capitalize(type)
// 		if (locations.length > 1) {
// 			titleText += ` # ${index + 1}`
// 		}

// 		timeline.push({
// 			key: each?.status,
// 			title: 'Moving To ' + titleText,
// 			time: each?.timestamp

// 		})
// 		timeline.push({
// 			key: each?.status,
// 			title: 'Reached ' + titleText,
// 			time: each?.timestamp,
// 		})
// 	})

// }

export const removeDuplicates = (arr) => {
	arr = arr || ['']
	const uniqueIds = new Set()
	const unique = arr.filter((element) => {
		const isDuplicate = uniqueIds.has(element.status)
		uniqueIds.add(element.status)
		if (!isDuplicate) {
			return true
		}
		return false
	})

	return unique
}

export const createLocationTimeline = (props) => {
	const { collection, timeline, locations, type, driverStatusHistory } = props
	const movingKey = type === 'loading' ? 'driverMovingToPickupLocation' : 'driverMovingToDropoffLocation'
	const reachedKey = type === 'loading' ? 'driverReachedPickupLocation' : 'driverReachedDropoffLocation'
	const movingHistoryStatuses = driverStatusHistory?.filter((status) => status.status === movingKey)
	const reachedHistoryStatuses = driverStatusHistory?.filter((status) => status.status === reachedKey)

	locations?.forEach((_, index) => {
		let titleText = ''
		titleText += (type === 'loading' && collection === 'import') || (type === 'dropoff' && collection === 'export') ? 'Port' : Capitalize(type)
		if (locations.length > 1) {
			titleText += ` # ${index + 1}`
		}
		timeline.push({
			key: movingKey,
			title: 'Moving to ' + titleText,
			time: movingHistoryStatuses?.[index]?.timestamp,
		})
		timeline.push({
			key: reachedKey,
			title: 'Reached ' + titleText,
			time: reachedHistoryStatuses?.[index]?.timestamp,
		})
	})
}

export const addConstantTimeline = (collection, timeline, driverStatusHistory) => {
	if (collection !== 'longHaul') {
		const movingKey = collection === 'import' ? 'driverMovingToContainerDropoffLocation' : 'driverMovingToContainerTerminal'
		const reachedKey = collection === 'import' ? 'driverReachedContainerDropoffLocation' : 'driverReachedContainerTerminal'
		timeline.splice(collection === 'import' ? timeline.length : 1, 0, {
			key: movingKey,
			title: collection === 'import' ? 'Moving to Empty Container Dropoff' : 'Moving to Release Terminal',
			time: driverStatusHistory?.find((status) => status.status === movingKey)?.timestamp,
		})
		timeline.splice(collection === 'import' ? timeline.length : 2, 0, {
			key: reachedKey,
			title: collection === 'import' ? 'Reached Empty Container Dropoff' : 'Reached Release Terminal',
			time: driverStatusHistory?.find((status) => status.status === reachedKey)?.timestamp,
		})
	}
}

const dotsDropdownMenu = ({ record, modalData, setModalData }) => (
	<Menu className='ProfileMenu'>
		{/* <Menu.Item key='downloadReport' onClick={() => setModalData({...modalData, visible: true, type: 'CommentThread', data: record})}>
            <Icon path={mdiFileDownloadOutline} size={1} className='HalfMarginRight'/>
            Download Report
        </Menu.Item> */}
		<Menu.Item
			key='addComment'
			onClick={() =>
				setModalData({
					...modalData,
					visible: true,
					type: 'CommentThread',
					data: record,
				})
			}
		>
			<Icon path={mdiCommentPlusOutline} size={1} className='HalfMarginRight' />
			Add Comment
		</Menu.Item>
	</Menu>
)

export const showContainerInformation = ({ record, data, showOrderNumber }) => {
	if (!record) return null
	const { containerInfo, eir } = record
	return (
		<div
			style={{
				backgroundColor: '#F5F5F5',
			}}
			key={record._id}
			className={
				showOrderNumber
					? 'Padding BorderRadius MarginRight NoWrap w-full mb-2'
					: 'LeftAlign QuarterPaddingTopBottom HalfPaddingLeftRight BorderRadius w-full mb-2'
			}
		>
			<div className='flex flex-row items-center justify-between'>
				<div className='FlexColumn'>
					<p className='BoldFont'>{`${containerInfo?.containerNumber ? `${containerInfo?.containerNumber}` : ''}`}</p>
					<p className='RegularFont GrayTintColor FontSize12'>{`${ContainerDimensionsEnum[containerInfo?.dimension]?.text} | ${
						containerInfo?.freightWeight
					} Tons`}</p>
				</div>

				{data?.job?.matched !== 'NonMatched' && record?.partialMatched && (
					<Tooltip
						placement='right'
						arrow={true}
						title={
							<div className='flex flex-row items-center justify-center w-fit text-sm'>
								<span className='text-white font-medium mr-1'>Moved</span>
								<span className='text-primary-800 font-bold mr-1'>{record.movementCount}</span>
								<span className='text-white font-medium mr-1'>out of</span>
								<span className='font-bold mr-1'>{record.totalCount}</span>
								<span className='text-whitefont-medium'>{record?.movedUnits}</span>
							</div>
						}
					>
						<Icon className='ml-5' path={mdiVectorLink} size={0.8} color='#1C75BC' />
					</Tooltip>
				)}
			</div>

			{eir?.infoComplete && eir?.receivedAt && <div className='BorderRadiusCircle PSBackground CenterAlign WhiteColor BoldFont '>EIR Submitted</div>}
			{eir?.infoComplete && eir?.receivedAt && (
				<div className='FlexRowBox FontSize12 '>
					<Icon path={mdiCalendar} size={0.8} className='QuarterMarginRight' />
					{eir?.infoComplete && eir?.receivedAt && moment(eir?.receivedAt).format('DD-MMM-YYYY HH:mm')}
				</div>
			)}
			{eir?.eirDocument?.length > 0 && (
				<p
					className='FullWidth FlexRowBox CenterAlign CursorPointer BoldFont'
					onClick={() => window.open(eir?.eirDocument?.[0], '_blank', 'noopener,noreferrer')}
				>
					EIR Doc
				</p>
			)}
		</div>
	)
}

export const getDriverLastStatus = (driverStatusHistory) => {
	const lastStatus = driverStatusHistory?.slice().sort((a, b) => moment(b?.createdAt).diff(moment(a.createdAt), 'seconds'))?.[0]
	const lastSubStatus = lastStatus?.DriverStatusJourneys?.slice().sort((a, b) => moment(b?.createdAt).diff(moment(a.createdAt), 'seconds'))?.[0]
	let lastLegitSubStatus = null
	if (lastSubStatus?.type === 'breakdown') {
		const lastStatusJourney = lastStatus?.DriverStatusJourneys?.slice().filter((subStatus) => subStatus.type !== 'breakdown')

		lastLegitSubStatus = lastStatusJourney[0] || null
	}
	return { lastStatus, lastSubStatus, lastLegitSubStatus }
}

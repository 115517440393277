import React, { useEffect, useState } from 'react'
import { Select, Alert, notification } from 'antd'
import GenerateReport from '../reports/GenerateReport'
import { Capitalize, EMAIL_REGEX } from '../helpers/Functions'

const { Option } = Select

const ModalSubheader = ({ emails, selectedEmails, setSelectedEmails, showError }) => {
	return (
		<>
			<div className='m-6'>
				<h4 className='font-bold'>Email Addresses</h4>
				<p id={'EmailBody'}>Order updates will be sent to client on these emails</p>
				<Select
					mode='tags'
					className='w-full pt-3'
					placeholder='Select Emails'
					value={selectedEmails}
					onChange={(value) => {
						setSelectedEmails(value.filter((item) => EMAIL_REGEX.test(item)))
					}}
				>
					{emails.length > 0 && emails.map((email) => <Option key={email}>{email}</Option>)}
				</Select>
				{showError === true && <Alert message='This field can not be left empty' type='error' />}
			</div>
		</>
	)
}

const DispatchEmail = (props) => {
	const { selectedRows, dispatchEmail, setDispatchEmail, setEmailModal, setModalData, reportType } = props

	const [emails, setEmails] = useState([])
	const [showError, setShowError] = useState(false)
	const [selectedEmails, setSelectedEmails] = useState([])

	useEffect(() => {
		const clientEmails = [...new Set(selectedRows?.reduce((acc, item) => (acc = [...acc, ...item?.clientEmails]), []))]
		setEmails(clientEmails)
		setSelectedEmails(clientEmails)
	}, [selectedRows]) //eslint-disable-line

	useEffect(() => {
		if (dispatchEmail) {
			onDispatchEmail()
		}
	}, [dispatchEmail]) //eslint-disable-line

	useEffect(() => {
		if (selectedEmails?.length === 0) {
			setShowError(true)
		} else {
			setShowError(false)
		}
	}, [selectedEmails, selectedEmails.length, showError])

	const onDispatchEmail = async () => {
		try {
			setModalData((prevState) => ({ ...prevState, loading: true }))
			// 'http://127.0.0.1:5001/bridgelinx-client/asia-south1/sendStatusUpdateEmail'
			await fetch(`https://asia-south1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendStatusUpdateEmail`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					data: {
						reportType: reportType
							.split(/(?=[A-Z])/)
							.map((letter) => Capitalize(letter))
							.join(' '),
						segment: selectedRows?.[0]?.type,
						orderNumber: selectedRows?.[0]?.orderNumber,
						documentNumber: selectedRows?.[0]?.documentNumber,
						businessName: selectedRows?.[0]?.businessName,
						shipmentInfo: selectedRows?.[0]?.shipmentInfo,
					},
					emails: selectedEmails,
					threadSubject: selectedRows?.[0]?.emailThread?.threadSubject,
					threadId: selectedRows?.[0]?.emailThread?.threadId,
					ccEmails: selectedRows?.[0]?.clientCCEmails,
					attachmentHTML: document.getElementById('Report').outerHTML,
				}),
			}).then((response) => {
				if (response.status === 200) {
					setModalData((prevState) => ({ ...prevState, loading: false }))
					setDispatchEmail(false)
					setEmailModal(false)
					notification.success({
						message: 'Email Dispatched',
						description: 'Email has been dispatched successfully',
					})
				} else {
					setModalData((prevState) => ({ ...prevState, loading: false }))
					setDispatchEmail(false)
					setEmailModal(false)
					notification.error({
						message: 'Email Dispatch Failed',
						description: 'Email dispatch failed, please try again',
					})
				}
			})
		} catch (err) {
			console.log(err)

			setModalData((prevState) => ({ ...prevState, loading: false }))
			setDispatchEmail(false)
			setEmailModal(false)
			notification.error({
				message: 'Email Dispatch Failed',
				description: 'Email dispatch failed, please try again',
			})
		}
	}

	return (
		<>
			<ModalSubheader emails={emails} selectedEmails={selectedEmails} setSelectedEmails={setSelectedEmails} showError={showError} />
			<GenerateReport {...props} />
		</>
	)
}

export default DispatchEmail

import { Button, Result } from 'antd'
import { LockTwoTone } from '@ant-design/icons'
const UnAuthorizedResult = ({ onClickFunction }) => (
	<Result
		icon={<LockTwoTone />}
		title={<p className='BoldFont NoMargin'>Not Authorized</p>}
		subTitle={
			<p className='NoMargin'>
				Sorry, your account role does not
				<br />
				allow you to access this portal.
			</p>
		}
		extra={
			<Button className='Button PrimaryButton' onClick={() => onClickFunction()}>
				Go Back
			</Button>
		}
	/>
)

export default UnAuthorizedResult

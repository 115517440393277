import { Menu } from 'antd'
import Icon from '@mdi/react'

import { mdiProgressCheck, mdiCheckCircle } from '@mdi/js'

export const DropdownMenu = (showActiveOrders, setShowActiveOrders) => (
	<Menu className='ProfileMenu' selectedKeys={[showActiveOrders]}>
		<Menu.Item key={true} onClick={() => setShowActiveOrders(true)}>
			<Icon path={mdiProgressCheck} size={1} className='HalfMarginRight' />
			Active Orders
		</Menu.Item>
		<Menu.Item key={false} onClick={() => setShowActiveOrders(false)}>
			<Icon path={mdiCheckCircle} size={1} className='HalfMarginRight' />
			Completed Orders
		</Menu.Item>
	</Menu>
)

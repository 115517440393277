import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../../recoil/atoms'
import { authSelector } from '../../recoil/selectors'
import { Navigate } from 'react-router-dom'
import { useRecoilStateCallback } from '../../hooks/useRecoilStateCallback'
export const PrivateRoute = (props) => {
	const [loading, setLoading] = useState(true)
	const [user, setUserAtom] = useRecoilStateCallback(userAtom)
	const isAuthenticated = useRecoilValue(authSelector())

	const { redirect } = props
	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem('user'))
		// const userData = JSON.parse(localStorage.getItem('user'))
		// const token = JSON.parse(localStorage.getItem('token'))?.token
		// const userToken = auth.currentUser?.getIdToken()
		// console.log(token, userData)
		if (userData) {
			setUserAtom(userData, () => setLoading(false))
		} else {
			setLoading(false)
		}
		// console.log(loading)
	}, []) // eslint-disable-line
	return isAuthenticated?.authorized ? <>{props.children}</> : loading ? <div>LOADING...</div> : <Navigate to={redirect} replace />
}

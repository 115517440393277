import { gql } from '@apollo/client'
import { ORDER_SUBSCRIPTION_FIELDS } from './fragments'

export const UPDATE_ORDER_SUBSCRIPTION = gql`
	${ORDER_SUBSCRIPTION_FIELDS}
	subscription OrderUpdatedByStatusAndTypeAndBusinessCode($status: [String!], $type: String, $businessCode: String) {
		OrderUpdatedByStatusAndTypeAndBusinessCode(status: $status, type: $type, businessCode: $businessCode) {
			...OrderSubscriptionFields
		}
	}
`

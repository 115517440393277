import { CREATE_COMMENT } from '../graphql/mutations'
import { Capitalize } from './Functions'
import { GET_USER, GET_ALL_ORDERS, GET_ALL_CORPORATES, ADVANCED_SEARCH } from '../graphql/queries'
import { UPDATE_ORDER_SUBSCRIPTION } from '../graphql/subscriptions'
import {
	importOrdersAtom,
	exportOrdersAtom,
	longHaulOrdersAtom,
	exportSearchAtom,
	importSearchAtom,
	longHaulSearchAtom,
	userAtom,
	corporatesAtom,
} from '../recoil/atoms'
import { mdiClockCheckOutline, mdiTextBoxCheckOutline, mdiTextBoxOutline } from '@mdi/js'

export const ProjectId = process.env.REACT_APP_PORTAL_PROJECT_ID
export const SegmentsEnum = {
	Import: {
		key: 'import',
		text: 'Import',
		long: 'Import Orders',
		initial: 'I',
		atomName: 'importOrdersAtom',
		collectionName: 'Import',
		// icon: mdiInboxArrowDownOutline,
		shortCode: 'IMP',
		textColor: '#3C7D8B',
		bgColor: '#E2F6FB',
		docType: 'BL',
		docTypeText: 'Bill of Lading',
		atom: importOrdersAtom,
		searchAtom: importSearchAtom,

		trackingEnum: {
			vehicleAssigned: {
				order: 0,
				key: 'vehicleAssigned',
				long: 'Vehicle Assigned',
				color: '#676767',
			},
			driverMovingToPickupLocation: {
				order: 1,
				key: 'driverMovingToPickupLocation',
				long: 'Moving To Port',
				color: '#1C75BC',
			},
			driverReachedPickupLocation: {
				order: 2,
				key: 'driverReachedPickupLocation',
				long: 'At Port',
				color: '#1C75BC',
			},
			driverMovingToDropoffLocation: {
				order: 3,
				key: 'driverMovingToDropoffLocation',
				long: 'En Route',
				color: '#467D48',
			},
			driverReachedDropoffLocation: {
				order: 4,
				key: 'driverReachedDropoffLocation',
				long: 'At Dropoff Location',
				color: '#467D48',
			},
			driverMovingToContainerDropoffLocation: {
				order: 5,
				key: 'driverMovingToContainerDropoffLocation',
				long: 'Moving to Container Terminal',
				color: '#467D48',
			},
			driverReachedContainerDropoffLocation: {
				order: 6,
				key: 'driverReachedContainerDropoffLocation',
				long: 'At Container Terminal',
				color: '#467D48',
			},
			substatus: {
				loadingInProcess: { key: 'loadingInProcess', long: 'Loading in Process' },
				loadingCompleted: { key: 'loadingCompleted', long: 'Loading Completed' },
				unloadingInProcess: { key: 'unloadingInProcess', long: 'Unloading in Process' },
				unloadingCompleted: { key: 'unloadingCompleted', long: 'Unloading Completed' },
			},
		},
	},
	Export: {
		key: 'export',
		text: 'Export',
		long: 'Export Orders',
		initial: 'E',
		atomName: 'exportOrdersAtom',
		collectionName: 'Export',
		// icon: mdiInboxArrowUpOutline,
		shortCode: 'EXP',
		textColor: '#467D48',
		bgColor: '#E4F7E5',
		docType: 'CRO',
		docTypeText: 'Container Release Order',
		atom: exportOrdersAtom,
		searchAtom: exportSearchAtom,
		trackingEnum: {
			vehicleAssigned: {
				order: 0,
				key: 'vehicleAssigned',
				long: 'Vehicle Assigned',
				color: '#676767',
			},
			driverMovingToContainerTerminal: {
				order: 1,
				key: 'driverMovingToContainerTerminal',
				long: 'Moving to Container Terminal',
				color: '#676767',
			},
			driverReachedContainerTerminal: {
				order: 2,
				key: 'driverReachedContainerTerminal',
				long: 'At Container Terminal',
				color: '#676767',
			},
			driverMovingToPickupLocation: {
				order: 3,
				key: 'driverMovingToPickupLocation',
				long: 'Moving To Loading',
				color: '#1C75BC',
			},
			driverReachedPickupLocation: {
				order: 4,
				key: 'driverReachedPickupLocation',
				long: 'At Loading Location',
				color: '#1C75BC',
			},
			driverMovingToDropoffLocation: {
				order: 5,
				key: 'driverMovingToDropoffLocation',
				long: 'En Route',
				color: '#467D48',
			},
			driverReachedDropoffLocation: {
				order: 6,
				key: 'driverReachedDropoffLocation',
				long: 'At Port',
				color: '#467D48',
			},
			substatus: {
				loadingInProcess: { key: 'loadingInProcess', long: 'Loading in Process' },
				loadingCompleted: { key: 'loadingCompleted', long: 'Loading Completed' },
				unloadingInProcess: { key: 'unloadingInProcess', long: 'Unloading in Process' },
				unloadingCompleted: { key: 'unloadingCompleted', long: 'Unloading Completed' },
			},
		},
	},
	LongHaul: {
		key: 'longHaul',
		text: 'Bulk',
		long: 'Bulk Orders',
		initial: 'L',
		atomName: 'longHaulOrdersAtom',
		collectionName: 'LongHaul',
		// icon: mdiWidgetsOutline,
		shortCode: 'BUL',
		textColor: '#7558B4',
		bgColor: '#F0E9FF',
		atom: longHaulOrdersAtom,
		searchAtom: longHaulSearchAtom,
		trackingEnum: {
			vehicleAssigned: {
				order: 0,
				key: 'vehicleAssigned',
				long: 'Vehicle Assigned',
				color: '#676767',
			},
			driverMovingToPickupLocation: {
				order: 3,
				key: 'driverMovingToPickupLocation',
				long: 'Moving To Loading',
				color: '#1C75BC',
			},
			driverReachedPickupLocation: {
				order: 4,
				key: 'driverReachedPickupLocation',
				long: 'At Loading Location',
				color: '#1C75BC',
			},
			driverMovingToDropoffLocation: {
				order: 5,
				key: 'driverMovingToDropoffLocation',
				long: 'Moving To Dropoff',
				color: '#467D48',
			},
			driverReachedDropoffLocation: {
				order: 6,
				key: 'driverReachedDropoffLocation',
				long: 'At Dropoff',
				color: '#467D48',
			},
			substatus: {
				loadingInProcess: { key: 'loadingInProcess', long: 'Loading in Process' },
				loadingCompleted: { key: 'loadingCompleted', long: 'Loading Completed' },
				unloadingInProcess: { key: 'unloadingInProcess', long: 'Unloading in Process' },
				unloadingCompleted: { key: 'unloadingCompleted', long: 'Unloading Completed' },
			},
		},
	},
}

export const GraphQLEnums = {
	Corporates: {
		fetch: {
			name: GET_ALL_CORPORATES,
			key: ['corporates'],
		},
		atoms: {
			corporate: corporatesAtom,
		},
	},
	Orders: {
		fetch: {
			name: GET_ALL_ORDERS,
			key: ['orders'],
			getVariables: ({ type, status, businessCode, dateRange }) => ({
				where: {
					type: type,
					createdAt_LTE: dateRange?.[1] ? dateRange[1].format('YYYY-MM-DD HH:mm:ss') : undefined,
					createdAt_GTE: dateRange?.[0] ? dateRange[0].format('YYYY-MM-DD HH:mm:ss') : undefined,
					businessCode: businessCode,
					ParentOrder: {
						[`Parent${Capitalize(type)}`]: {
							[`Sub${Capitalize(type)}s_SOME`]: {
								status_IN: status,
							},
						},
					},
				},
				options: { sort: [{ createdAt: 'DESC' }] },
				biltyOptions2: { sort: [{ createdAt: 'DESC' }] },
			}),
		},
		subscription: { name: UPDATE_ORDER_SUBSCRIPTION, key: 'OrderUpdatedByStatusAndTypeAndBusinessCode' },
		atoms: {
			import: importOrdersAtom,
			export: exportOrdersAtom,
			longHaul: longHaulOrdersAtom,
		},
	},
	AdvancedSearch: {
		fetch: {
			name: ADVANCED_SEARCH,
			key: ['orders'],
		},
		atoms: {
			import: importSearchAtom,
			export: exportSearchAtom,
			longHaul: longHaulSearchAtom,
		},
	},
	User: {
		fetch: {
			name: GET_USER,
			key: ['users', '0'],
			getVariables: ({ _id }) => ({ where: { _id: _id } }),
		},
		atoms: {
			user: userAtom,
		},
	},
	Comments: {
		create: { name: CREATE_COMMENT, key: ['createComments'], createKey: 'input' },
	},
}

// mutation: {},

export const PageSizes = {
	10: { frontEnd: 10, backend: 60 },
	20: { frontEnd: 20, backend: 100 },
	50: { frontEnd: 50, backend: 250 },
	100: { frontEnd: 100, backend: 500 },
}

export const PagesEnum = {
	import: {
		key: 'import',
		icon: '#ImportOrders',
		long: 'Import Orders',
		initial: 'I',
		atomName: 'importOrdersAtom',
		searchAtomName: 'importSearchAtom',
	},
	export: {
		key: 'export',
		icon: '#ExportOrders',
		long: 'Export Orders',
		initial: 'E',
		atomName: 'exportOrdersAtom',
		searchAtomName: 'exportSearchAtom',
	},
	longHaul: {
		key: 'longHaul',
		icon: '#LongHaulOrders',
		long: 'Bulk Orders',
		initial: 'L',
		atomName: 'bulkOrdersAtom',
		searchAtomName: 'bulkSearchAtom',
	},
}

export const OrderStatusEnum = {
	'Order Pending': {
		text: 'Pending',
		long: 'Order Pending',
		background: '#F2F2F2',
		color: '#808080',
	},
	'Order Confirmed': {
		text: 'Confirmed',
		long: 'Order Confirmed',
		background: '#E5F4FF',
		color: '#008EFF',
	},
	'Partial Ongoing': {
		text: 'Partial Moved',
		long: 'Partial Ongoing',
		background: '#FFF4E5',
		color: '#FF9500',
	},
	'Order Ongoing': {
		text: 'Ongoing',
		long: 'Order Ongoing',
		background: '#FFF4E5',
		color: '#FF9500',
	},
	'Partial Completed': {
		text: 'Partial Moved',
		long: 'Partial Completed',
		background: '#FFF4E5',
		color: '#FF9500',
	},
	'Order Completed': {
		text: 'Completed',
		long: 'Order Completed',
		background: '#E5FFE7',
		color: '#40BF4A',
	},
	'Order Cancelled': {
		text: 'Cancelled',
		long: 'Order Cancelled',
		background: '#FFE5EA',
		color: '#FF1500',
	},
}

export const ContainerDimensionsEnum = {
	xl20ft: { key: 'xl20ft', text: '20 Ft', long: '20 Feet', icon: '#xl20ft' },
	xl40ft: { key: 'xl40ft', text: '40 Ft', long: '40 Feet', icon: '#xl40ft' },
	xl40ftHC: { key: 'xl40ftHC', text: '40 Ft High Cube', long: '40 Feet - High Cube', icon: '#xl40ftHC' },
	xl40ftOT: { key: 'xl40ftOT', text: '40 Ft Open Top', long: '40 Feet - Open Top', icon: '#xl40ftOT' },
	flatbed: { key: 'flatbed', text: 'Flatbed', long: 'Flatbed', icon: '' },
	dumper: { key: 'dumper', text: 'Dumper', long: 'Dumper', icon: '' },
	halfBody: { key: 'halfBody', text: 'Half Body', long: 'Half Body', icon: '' },
	other: { key: 'other', text: 'Other', long: 'Other', icon: '#other' },
}

export const DoStatuses = {
	'NOT STARTED': {
		key: 'notStarted',
		text: 'NOT STARTED',
		icon: mdiClockCheckOutline,
		background: '#556471',
	},
	'DOC RECEIVED': {
		key: 'docReceived',
		text: 'DOC RECEIVED',
		icon: mdiTextBoxOutline,
		background: '#D99249',
	},
	'DOC SUBMITTED': {
		key: 'docSubmitted',
		text: 'DOC SUBMITTED',
		icon: mdiTextBoxOutline,
		background: '#2873AF',
	},
	'DOC RETURNED': {
		key: 'docReturned',
		text: 'DOC RETURNED',
		icon: mdiTextBoxOutline,
		background: '#B55050',
	},
	'GATE PASS': {
		key: 'gatePass',
		text: 'GATE PASS',
		icon: mdiClockCheckOutline,
		background: '#46978D',
	},
	'DO ISSUED - ONLY': {
		key: 'doIssuedOnly',
		text: 'DO ISSUED - ONLY',
		icon: mdiTextBoxCheckOutline,
		background: '#556471',
	},
	'VEHICLE ONLY': {
		key: 'vehicleOnly',
		text: 'VEHICLE ONLY',
		icon: mdiTextBoxCheckOutline,
		background: '#556471',
	},
	'DO ISSUED': {
		key: 'doIssued',
		text: 'DO ISSUED',
		icon: mdiTextBoxCheckOutline,
		background: '#46974A',
	},
}

export const orderCategories = {
	destuffing: {
		key: 'destuffing',
		text: 'destuffing',
		color: 'WhiteColor',
		backgroundColor: '#607D8B',
	},
	shifting: {
		key: 'shifting',
		text: 'shifting',
		color: 'BlackColor',
		backgroundColor: '#FFC700',
	},
	doubleTwenty: {
		key: 'DoubleTwenty',
		text: 'Double Twenty',
		color: 'WhiteColor',
		backgroundColor: 'rgb(156,163,175)',
	},
	lot: {
		key: 'lot',
		text: 'Lot Order',
		color: 'BlackColor',
		backgroundColor: '#FFC700',
	},
}

export const ReportTypeEnums = {
	Import: [
		{ key: 'dispatchReport', label: <div className='font-extrabold'>Dispatch Report</div> },
		{ key: 'transitReport', label: <div className='font-extrabold'>Transit Report</div> },
	],
	Export: [
		{ key: 'dispatchReport', label: <div className='font-extrabold'>Dispatch Report</div> },
		{ key: 'transitReport', label: <div className='font-extrabold'>Transit Report</div> },
	],
	LongHaul: [{ key: 'orderStatusReport', label: <div className='font-extrabold'>Order Status Report</div> }],
}

import moment from 'moment'
import ReportLayoutEnums from './ReportLayoutEnums'
import { SegmentsEnum } from '../helpers/Enums'
const ReportHeader = (props) => {
	const { businessName, segment, orderNumber, documentNumber, type, orderCategory, shipmentInfo, orderLocations, reportType } = props

	const dropoff = orderLocations?.filter((location) => location?.locationType === 'dropoff')
	const loading = orderLocations?.filter((location) => location?.locationType === 'loading')

	return (
		<>
			<div
				style={{
					height: 60,
					width: '100%',
					color: '#FFF',
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'row',
					background: '#004AAD',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '33.3%', padding: '0 20px', gap: '10px' }}>
					<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
						<rect width='40' height='40' rx='4' fill='#0261B5' />
						<path
							d='M20.0043 22.7253C17.1906 22.6714 15 20.3724 15 17.7142V11.7333C15 8.48237 16.964 6.25523 19.6267 6.02174C22.4404 5.77029 24.952 7.72802 25.2164 10.4221C25.2353 10.5658 25.2353 10.7275 25.2353 10.8712V16.5731C25.2353 17.1133 24.5587 17.391 24.14 17.0497C23.4342 16.4791 22.8748 15.6991 22.8748 14.7916V10.9789C22.8748 9.63186 21.855 8.42849 20.4576 8.26684C18.9279 8.06927 17.5305 9.09304 17.3228 10.5479C17.3039 10.6556 17.3039 10.7634 17.3039 10.8891V17.7501C17.3039 19.2768 18.6258 20.5879 20.2121 20.5161H20.2876C21.1374 20.552 21.4962 20.8394 21.7417 21.4141C21.9941 22.0543 21.3585 22.6991 20.671 22.7296C20.4475 22.7395 20.2247 22.7384 20.0043 22.7253Z'
							fill='white'
						/>
						<path
							d='M20.2498 16.8697C23.0636 16.9236 25.2542 19.2226 25.2542 21.8987V27.8617C25.2542 31.1126 23.2713 33.3397 20.6275 33.5732C17.7949 33.8247 15.3021 31.849 15.0378 29.1728C15 29.0112 15 28.8675 15 28.7238V23.0219C15 22.4817 15.6766 22.204 16.0953 22.5453C16.7951 23.1111 17.3417 23.8869 17.3417 24.7868V28.634C17.3417 29.9811 18.3614 31.1845 19.7588 31.3461C21.2885 31.5437 22.6859 30.5199 22.8936 29.0651C22.9125 28.9573 22.9125 28.8316 22.9125 28.7238V21.8628C22.9125 20.3362 21.5906 19.025 20.0043 19.0969H19.9666C19.1357 19.0609 18.758 18.7736 18.5125 18.1988C18.2585 17.5546 18.8887 16.8988 19.5803 16.866C19.8016 16.8555 20.0245 16.8567 20.2498 16.8697Z'
							fill='white'
						/>
					</svg>
					<div style={{ fontSize: '15px', fontWeight: 600 }}>BridgeLinx</div>
				</div>
				<div style={{ fontSize: '16px', fontWeight: 700, textAlign: 'center', width: '33.3%', padding: '0 20px' }}>
					{reportType
						.split(/(?=[A-Z])/)
						.map((letter) => letter.toUpperCase())
						.join(' ')}
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '40px', width: '33.3%', padding: '0 20px', justifyContent: 'end' }}>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
						<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
							<g clip-path='url(#clip0_883_566)'>
								<path
									d='M11.3333 1.33337C10.9667 1.33337 10.6667 1.63337 10.6667 2.00004V2.66671H5.33333V2.00004C5.33333 1.63337 5.03333 1.33337 4.66667 1.33337C4.3 1.33337 4 1.63337 4 2.00004V2.66671H3.33333C2.59333 2.66671 2.00667 3.26671 2.00667 4.00004L2 13.3334C2 14.0667 2.59333 14.6667 3.33333 14.6667H12.6667C13.4 14.6667 14 14.0667 14 13.3334V4.00004C14 3.26671 13.4 2.66671 12.6667 2.66671H12V2.00004C12 1.63337 11.7 1.33337 11.3333 1.33337ZM12.6667 13.3334H3.33333V6.66671H12.6667V13.3334ZM7.33333 8.66671C7.33333 8.30004 7.63333 8.00004 8 8.00004C8.36667 8.00004 8.66667 8.30004 8.66667 8.66671C8.66667 9.03337 8.36667 9.33337 8 9.33337C7.63333 9.33337 7.33333 9.03337 7.33333 8.66671ZM4.66667 8.66671C4.66667 8.30004 4.96667 8.00004 5.33333 8.00004C5.7 8.00004 6 8.30004 6 8.66671C6 9.03337 5.7 9.33337 5.33333 9.33337C4.96667 9.33337 4.66667 9.03337 4.66667 8.66671ZM10 8.66671C10 8.30004 10.3 8.00004 10.6667 8.00004C11.0333 8.00004 11.3333 8.30004 11.3333 8.66671C11.3333 9.03337 11.0333 9.33337 10.6667 9.33337C10.3 9.33337 10 9.03337 10 8.66671ZM7.33333 11.3334C7.33333 10.9667 7.63333 10.6667 8 10.6667C8.36667 10.6667 8.66667 10.9667 8.66667 11.3334C8.66667 11.7 8.36667 12 8 12C7.63333 12 7.33333 11.7 7.33333 11.3334ZM4.66667 11.3334C4.66667 10.9667 4.96667 10.6667 5.33333 10.6667C5.7 10.6667 6 10.9667 6 11.3334C6 11.7 5.7 12 5.33333 12C4.96667 12 4.66667 11.7 4.66667 11.3334ZM10 11.3334C10 10.9667 10.3 10.6667 10.6667 10.6667C11.0333 10.6667 11.3333 10.9667 11.3333 11.3334C11.3333 11.7 11.0333 12 10.6667 12C10.3 12 10 11.7 10 11.3334Z'
									fill='white'
								/>
							</g>
							<defs>
								<clipPath id='clip0_883_566'>
									<rect width='18' height='18' fill='white' />
								</clipPath>
							</defs>
						</svg>
						<div style={{ fontSize: '14px', fontWeight: 600 }}>{moment().format('DD-MM-YYYY')}</div>
					</div>

					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
						<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
							<g clip-path='url(#clip0_883_578)'>
								<path
									d='M8.66683 10.6667H7.3335C6.96683 10.6667 6.66683 10.3667 6.66683 10H2.00683V12.6667C2.00683 13.4 2.60683 14 3.34016 14H12.6668C13.4002 14 14.0002 13.4 14.0002 12.6667V10H9.3335C9.3335 10.3667 9.0335 10.6667 8.66683 10.6667ZM13.3335 4.66667H10.6668C10.6668 3.19333 9.4735 2 8.00016 2C6.52683 2 5.3335 3.19333 5.3335 4.66667H2.66683C1.9335 4.66667 1.3335 5.26667 1.3335 6V8C1.3335 8.74 1.92683 9.33333 2.66683 9.33333H6.66683V8.66667C6.66683 8.3 6.96683 8 7.3335 8H8.66683C9.0335 8 9.3335 8.3 9.3335 8.66667V9.33333H13.3335C14.0668 9.33333 14.6668 8.73333 14.6668 8V6C14.6668 5.26667 14.0668 4.66667 13.3335 4.66667ZM6.66683 4.66667C6.66683 3.93333 7.26683 3.33333 8.00016 3.33333C8.7335 3.33333 9.3335 3.93333 9.3335 4.66667H6.66016H6.66683Z'
									fill='white'
								/>
							</g>
							<defs>
								<clipPath id='clip0_883_578'>
									<rect width='18' height='18' fill='white' />
								</clipPath>
							</defs>
						</svg>
						<div style={{ fontSize: '14px', fontWeight: 600 }}>{businessName}</div>
					</div>
				</div>
			</div>
			<div
				style={{
					height: 48,
					width: '100%',
					color: '#FFF',
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'row',
					background: '#E2F2FF',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						color: '#004AAD',
						fonstSize: '16px',
						fontWeight: 500,
						width: '33.3%',
						padding: '0 20px',
					}}
				>
					Order&nbsp;#&nbsp;
					<span style={{ fontWeight: 700 }}>
						{SegmentsEnum[segment].initial}-{orderNumber}
					</span>
				</div>
				<div
					style={{
						width: '33.3%',
						padding: '0 20px',
						fontWeight: 700,
						color: '#004AAD',
						fontSize: '16px',
						textAlign: 'center',
						alignSelf: 'center',
					}}
				>
					{type !== 'LongHaul' ? (
						<span>
							<span style={{ fontWeight: 400 }}>{SegmentsEnum[segment].docType}&nbsp;#&nbsp;</span>
							<span>{documentNumber}</span>
						</span>
					) : (
						`${shipmentInfo?.freightType?.toUpperCase()} - ${shipmentInfo?.freightWeight} TONNES`
					)}
				</div>
				<div style={{ width: '33.3%', display: 'flex', justifyContent: 'end', padding: '0 20px' }}>
					<div
						style={{
							fontSize: '11px',
							fontWeight: 700,
							width: 'fit-content',
							background: `${ReportLayoutEnums[segment][orderCategory]?.['background-color']}`,
							padding: '4px 12px',
							borderRadius: '100px',
						}}
					>
						{ReportLayoutEnums[segment][orderCategory]?.type} {type !== 'Export' ? '-' : ''} {ReportLayoutEnums[segment][orderCategory]?.category}
					</div>
				</div>
			</div>
			<div
				style={{
					minHeight: 42,
					width: '100%',
					color: '#FFF',
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'row',
					background: '#F0F8FF',
				}}
			>
				{loading?.map((location) => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							paddingLeft: '20px',
							marginRight: '28px',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								background: '#1C75BC',
								borderRadius: '100%',
								width: '18px',
								height: '18px',
								justifyContent: 'center',
								alignItems: 'center',
								marginRight: '8px',
							}}
						>
							<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<circle cx='10' cy='10' r='10' fill='#1C75BC' />
								<path
									d='M9.99962 10.1071C9.62928 10.1071 9.27412 9.96554 9.01225 9.7135C8.75039 9.46145 8.60327 9.1196 8.60327 8.76316C8.60327 8.40671 8.75039 8.06486 9.01225 7.81282C9.27412 7.56077 9.62928 7.41917 9.99962 7.41917C10.37 7.41917 10.7251 7.56077 10.987 7.81282C11.2489 8.06486 11.396 8.40671 11.396 8.76316C11.396 8.93965 11.3598 9.11442 11.2897 9.27748C11.2195 9.44054 11.1166 9.5887 10.987 9.7135C10.8573 9.8383 10.7034 9.9373 10.534 10.0048C10.3646 10.0724 10.183 10.1071 9.99962 10.1071ZM9.99962 5C8.96268 5 7.96822 5.39647 7.23499 6.1022C6.50177 6.80793 6.08984 7.76511 6.08984 8.76316C6.08984 11.5855 9.99962 15.7519 9.99962 15.7519C9.99962 15.7519 13.9094 11.5855 13.9094 8.76316C13.9094 7.76511 13.4975 6.80793 12.7642 6.1022C12.031 5.39647 11.0366 5 9.99962 5Z'
									fill='white'
								/>
							</svg>
						</div>
						<span
							style={{
								color: '#5E5E5E',
								textAlign: 'center',
								fontSize: '12px',
								fontWeight: 700,
								marginRight: '4px',
							}}
						>
							Pick-up:
						</span>
						<span
							style={{
								color: '#333333',
								textAlign: 'center',
								fontSize: '12px',
								fontWeight: 500,
							}}
						>
							{location?.Location?.formattedAddress}, {location?.Location?.additionalDetails}
						</span>
					</div>
				))}
				{dropoff?.map((location) => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							paddingRight: '20px',
							marginRight: '28px',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								display: 'flex',
								background: '#467D48',
								borderRadius: '100%',
								width: '18px',
								height: '18px',
								justifyContent: 'center',
								alignItems: 'center',
								marginRight: '8px',
							}}
						>
							<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
								<circle cx='10' cy='10' r='10' fill='#467D48' />
								<path
									id='Vector'
									d='M9.99962 10.1071C9.62928 10.1071 9.27412 9.96555 9.01225 9.7135C8.75038 9.46145 8.60327 9.11961 8.60327 8.76316C8.60327 8.40671 8.75038 8.06486 9.01225 7.81282C9.27412 7.56077 9.62928 7.41917 9.99962 7.41917C10.37 7.41917 10.7251 7.56077 10.987 7.81282C11.2489 8.06486 11.396 8.40671 11.396 8.76316C11.396 8.93965 11.3598 9.11442 11.2897 9.27748C11.2195 9.44054 11.1166 9.5887 10.987 9.7135C10.8573 9.8383 10.7034 9.9373 10.534 10.0048C10.3646 10.0724 10.183 10.1071 9.99962 10.1071ZM9.99962 5C8.96268 5 7.96821 5.39647 7.23499 6.1022C6.50177 6.80793 6.08984 7.76511 6.08984 8.76316C6.08984 11.5855 9.99962 15.7519 9.99962 15.7519C9.99962 15.7519 13.9094 11.5855 13.9094 8.76316C13.9094 7.76511 13.4975 6.80793 12.7642 6.1022C12.031 5.39647 11.0366 5 9.99962 5Z'
									fill='white'
								/>
							</svg>
						</div>
						<span
							style={{
								color: '#5E5E5E',
								textAlign: 'center',
								fontSize: '12px',
								fontWeight: 700,
								marginRight: '4px',
							}}
						>
							Drop-off:
						</span>
						<span
							style={{
								color: '#333333',
								textAlign: 'center',
								fontSize: '12px',
								fontWeight: 500,
							}}
						>
							{location?.Location?.formattedAddress}, {location?.Location?.additionalDetails}
						</span>
					</div>
				))}
			</div>
		</>
	)
}

export default ReportHeader

import { Alert } from 'antd'

const AlertBanner = (alert, setAlert) => (
	<Alert
		type={alert.type}
		message={alert.message}
		showIcon
		closable
		className={`BoldFont LeftAlign MarginBottom ${alert.type}`}
		afterClose={() => setAlert({ type: '', message: '' })}
	/>
)
export default AlertBanner

import { atom } from 'recoil'

export const userAtom = atom({ key: 'userAtom', default: {} })
export const corporatesAtom = atom({ key: 'corporatesAtom', default: [] })

export const importOrdersAtom = atom({ key: 'importOrdersAtom', default: [] }) // prettier-ignore
export const exportOrdersAtom = atom({ key: 'exportOrdersAtom', default: [] }) // prettier-ignore
export const longHaulOrdersAtom = atom({ key: 'longHaulOrdersAtom', default: [] }) // prettier-ignore

// export const importLimitsAtom =atom({ key: 'importLimitsAtom', default: {start: startOfDay, end: endOfDay} }) // prettier-ignore
// export const exportLimitsAtom =atom({ key: 'exportLimitsAtom', default: {start: startOfDay, end: endOfDay} }) // prettier-ignore
// export const bulkLimitsAtom =atom({ key: 'bulkLimitsAtom', default: {start: startOfDay, end: endOfDay} }) // prettier-ignore

export const importSearchAtom = atom({ key: 'importSearchAtom', default: [] })
export const exportSearchAtom = atom({ key: 'exportSearchAtom', default: [] })
export const longHaulSearchAtom = atom({ key: 'longHaulSearchAtom', default: [] })
// export const searchOrdersAtom = atom({ key: 'searchOrdersAtom', default: [] })
export const tokenAtom = atom({ key: 'tokenAtom', default: {} })
// export const groupedOrdersAtom = atom({ key: 'groupedOrdersAtom', default: [] }) // prettier-ignore

/* ============================================================================================= */
/* ==================================== Report Layout Enums ==================================== */
/* ============================================================================================= */

import {
	BalesMovedColumn,
	BiltyNumberColumn,
	CommittedDeliveryDateRow,
	CommodityRow,
	ContainerDimensionColumn,
	ContainerNumberColumn,
	DriverPhoneNumberColumn,
	EmptyReturnColumn,
	JobMovedBalesColumn,
	JobNumberColumn,
	LastLocationChip,
	LastLocationTimeChip,
	LeftPortAtColumn,
	LoadedDepartedAtColumn,
	LoadedWeightColumn,
	LotUnitsMovedColumn,
	PendingAssignmentColumn,
	ReachedDropoffAtColumn,
	ReachedPickupAtColumn,
	ReleaseTerminalRow,
	RemainingBalesColumn,
	RemainingWeightColumn,
	ReturnDeadlineClientRow,
	ReturnDeadlineSLRow,
	ShippingLineRow,
	StatusColumn,
	TotalContainersColumn,
	TotalLoadedBalesRow,
	TotalLoadedWeightRow,
	TotalVehiclesColumn,
	TotalWeightMovedColumn,
	UnloadingCompletedAtColumn,
	VehicleNumberColumn,
	VehiclesAssignedColumn,
	VesselCutoffDateRow,
} from './dispatchReport/DispatchReportColumns'
import {
	VehicleAndPhoneColumn,
	ContainerAndDimensionColumn,
	StatusAndTimeColumn,
	ReachedEmptyAtColumn,
	EmptyReturnedAtColumn,
	ReachedContainerTerminalAt,
	LeftContainerTerminalAt,
} from './transitReport/TransitReportColumns'

const ReportLayoutEnums = {
	Import: {
		'direct-seal': {
			type: 'IMPORT',
			category: 'DIRECT SEAL',
			'background-color': '#1C75BC',
			dispatchReport: {
				info: [CommodityRow, ShippingLineRow, ReturnDeadlineClientRow, ReturnDeadlineSLRow],
				table: [
					VehicleNumberColumn,
					JobNumberColumn,
					ContainerNumberColumn,
					ContainerDimensionColumn,
					BiltyNumberColumn,
					DriverPhoneNumberColumn,
					StatusColumn,
					EmptyReturnColumn,
				],
				expandedRow: [],
				summary: [TotalContainersColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
			transitReport: {
				info: [CommodityRow, ShippingLineRow, ReturnDeadlineClientRow, ReturnDeadlineSLRow],
				table: [
					VehicleAndPhoneColumn,
					ContainerAndDimensionColumn,
					BiltyNumberColumn,
					StatusAndTimeColumn,
					LeftPortAtColumn,
					ReachedDropoffAtColumn,
					UnloadingCompletedAtColumn,
					ReachedEmptyAtColumn,
					EmptyReturnedAtColumn,
				],
				expandedRow: [LastLocationChip, LastLocationTimeChip],
				summary: [TotalContainersColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
		},
		shifting: {
			type: 'IMPORT',
			category: 'SHIFTING',
			'background-color': '#3C7D8B',
			dispatchReport: {
				info: [CommodityRow, TotalLoadedWeightRow, TotalLoadedBalesRow],
				table: [VehicleNumberColumn, JobNumberColumn, BiltyNumberColumn, JobMovedBalesColumn, LoadedWeightColumn, DriverPhoneNumberColumn, StatusColumn],
				expandedRow: [],
				summary: [TotalContainersColumn, BalesMovedColumn, RemainingBalesColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
			transitReport: {
				info: [CommodityRow, TotalLoadedWeightRow, TotalLoadedBalesRow],
				table: [
					VehicleAndPhoneColumn,
					ContainerAndDimensionColumn,
					BiltyNumberColumn,
					JobMovedBalesColumn,
					LoadedWeightColumn,
					StatusAndTimeColumn,
					LoadedDepartedAtColumn,
					ReachedDropoffAtColumn,
					UnloadingCompletedAtColumn,
				],
				expandedRow: [LastLocationChip, LastLocationTimeChip],
				summary: [TotalContainersColumn, BalesMovedColumn, RemainingBalesColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
		},
		destuffing: {
			type: 'IMPORT',
			category: 'DESTUFFING',
			'background-color': '#3C7D8B',
			dispatchReport: {
				info: [CommodityRow, TotalLoadedWeightRow, TotalLoadedBalesRow],
				table: [VehicleNumberColumn, JobNumberColumn, BiltyNumberColumn, JobMovedBalesColumn, LoadedWeightColumn, DriverPhoneNumberColumn, StatusColumn],
				expandedRow: [],
				summary: [TotalContainersColumn, BalesMovedColumn, RemainingBalesColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
			transitReport: {
				info: [CommodityRow, TotalLoadedWeightRow, TotalLoadedBalesRow],
				table: [
					VehicleAndPhoneColumn,
					ContainerAndDimensionColumn,
					BiltyNumberColumn,
					JobMovedBalesColumn,
					LoadedWeightColumn,
					StatusAndTimeColumn,
					LoadedDepartedAtColumn,
					ReachedDropoffAtColumn,
					UnloadingCompletedAtColumn,
				],
				expandedRow: [LastLocationChip, LastLocationTimeChip],
				summary: [TotalContainersColumn, BalesMovedColumn, RemainingBalesColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
		},
	},
	Export: {
		'direct-seal': {
			type: 'EXPORT',
			category: '',
			'background-color': '#467D48',
			dispatchReport: {
				info: [CommodityRow, CommittedDeliveryDateRow, ReleaseTerminalRow, ShippingLineRow, VesselCutoffDateRow],
				table: [
					VehicleNumberColumn,
					JobNumberColumn,
					ContainerNumberColumn,
					ContainerDimensionColumn,
					BiltyNumberColumn,
					DriverPhoneNumberColumn,
					StatusColumn,
				],
				expandedRow: [],
				summary: [TotalContainersColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
			transitReport: {
				info: [CommodityRow, CommittedDeliveryDateRow, ReleaseTerminalRow, ShippingLineRow, VesselCutoffDateRow],
				table: [
					VehicleAndPhoneColumn,
					ContainerAndDimensionColumn,
					BiltyNumberColumn,
					StatusAndTimeColumn,
					ReachedContainerTerminalAt,
					LeftContainerTerminalAt,
					ReachedPickupAtColumn,
					LoadedDepartedAtColumn,
					ReachedDropoffAtColumn,
				],
				expandedRow: [LastLocationChip, LastLocationTimeChip],
				summary: [TotalContainersColumn, TotalVehiclesColumn, VehiclesAssignedColumn, PendingAssignmentColumn],
			},
		},
	},
	LongHaul: {
		lot: {
			type: 'BULK',
			category: 'LOT',
			'background-color': '#7558B4',
			orderStatusReport: {
				info: [],
				table: [
					VehicleNumberColumn,
					BiltyNumberColumn,
					LoadedWeightColumn,
					DriverPhoneNumberColumn,
					StatusAndTimeColumn,
					LoadedDepartedAtColumn,
					ReachedDropoffAtColumn,
					UnloadingCompletedAtColumn,
				],
				expandedRow: [LastLocationChip, LastLocationTimeChip],
				summary: [TotalWeightMovedColumn, RemainingWeightColumn, LotUnitsMovedColumn, VehiclesAssignedColumn],
			},
		},
		'non-lot': {
			type: 'BULK',
			category: 'CONTAINERIZED',
			'background-color': '#A06E22',
			orderStatusReport: {
				info: [],
				table: [
					VehicleNumberColumn,
					BiltyNumberColumn,
					LoadedWeightColumn,
					DriverPhoneNumberColumn,
					StatusAndTimeColumn,
					ReachedPickupAtColumn,
					LoadedDepartedAtColumn,
					ReachedDropoffAtColumn,
					UnloadingCompletedAtColumn,
				],
				expandedRow: [LastLocationChip, LastLocationTimeChip],
				summary: [TotalWeightMovedColumn, RemainingWeightColumn, TotalVehiclesColumn, VehiclesAssignedColumn],
			},
		},
	},
}

export default ReportLayoutEnums

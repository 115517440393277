import React from 'react'
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom'

import Login from './containers/Login'
import './App.css'
import './tw.css'

// import { auth } from './firebase/firebaseConfig'

import { PrivateRoute } from './components/utility/PrivateRoute'
// import { onAuthStateChanged } from './helpers/Functions'
import Homepage from './containers/Homepage'

const App = () => {
	// 	useEffect(() => {
	// 		auth.onAuthStateChanged(onAuthStateChanged)
	// 	}, []) // eslint-disable-line

	return (
		<Router>
			<Routes>
				<Route path='/' exact element={<Navigate to='/import' replace />} />
				<Route
					path=':segment'
					exact
					element={
						<PrivateRoute redirect='/login'>
							<Homepage />
						</PrivateRoute>
					}
				/>
				<Route path='/login' element={<Login />} />
				{/* <Route path={['/import', '/export', '/longHaul']} render={(props) => <DynamicTable {...props} />} exact /> */}
			</Routes>
		</Router>
	)
}

export default App

import { gql } from '@apollo/client'

import { USER_FIELDS } from './fragments'
export const CREATE_COMMENT = gql`
	${USER_FIELDS}
	mutation CreateComments($input: [CommentCreateInput!]!) {
		createComments(input: $input) {
			comments {
				_id
				Author {
					...UserFields
				}
				comment
				createdAt
				updatedAt
			}
		}
	}
`

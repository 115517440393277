import React, { useState } from 'react'
import { Layout } from 'antd'
import Header from '../components/Header'
import TableErrorBoundary from '../components/errors/TableError'
import { ErrorBoundary } from 'react-error-boundary'

import DynamicTable from '../containers/DynamicTable'
import Sidebar from '../components/Sidebar'

const Homepage = (props) => {
	const [collapseMenu, setCollapseMenu] = useState(true)
	const [currentPage, setCurrentPage] = useState()
	return (
		<Layout>
			<Header setCollapseMenu={setCollapseMenu} currentPage={currentPage} />
			<Layout>
				<Sidebar collapseMenu={collapseMenu} currentPage={currentPage} setCurrentPage={setCurrentPage} />
				<ErrorBoundary FallbackComponent={TableErrorBoundary} onError={(error, info) => console.log(error)}>
					<DynamicTable {...props} />
				</ErrorBoundary>
			</Layout>
		</Layout>
	)
}

export default Homepage

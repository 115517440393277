import { Timeline } from 'antd'
const LocationsTimeline = ({ loading, dropoff, clearingAgents, linkedBulkOrders }) => {
	const locations = [...(loading || []), ...(dropoff || [])]
	if (linkedBulkOrders) {
		const bulkDropoffs = linkedBulkOrders?.[0]?.dropOff && Object.values(linkedBulkOrders?.[0]?.dropOff)?.sort((a, b) => a.number - b.number)
		bulkDropoffs?.map((location) => locations.push(location))
	}
	return (
		<>
			<Timeline className='HalfPaddingExceptTop'>
				{locations.map((location, index) => (
					<Timeline.Item key={index} color={location.locationType === 'loading' ? '#0066CC' : '#00CBCB'}>
						<p className={`BoldFont ${location?.locationType === 'loading' ? 'PrimaryColor' : 'SecondaryColor'}`}>{location?.formattedAddress}</p>
						<p className='FontSize12'>{location?.additionalDetails}</p>
					</Timeline.Item>
				))}
			</Timeline>
			{clearingAgents && (
				<div className='GHBackground HalfPadding'>
					<p className='BoldFont FontSize12'>Clearing Agents:</p>
					{clearingAgents?.map((agent, index) => (
						<p key={index} className='FontSize12'>{`${agent.contactName} - ${agent.contactPhone}`}</p>
					))}
				</div>
			)}
		</>
	)
}

export default LocationsTimeline

import React from 'react'
import ReactExport from 'react-export-excel'
import Icon from '@mdi/react'
import { mdiFileDownloadOutline } from '@mdi/js'
import { Button } from 'antd'
import { ReadableString, get24HourDate } from '../helpers/Functions'
import { ContainerDimensionsEnum, OrderStatusEnum } from '../helpers/Enums'
import { getDriverLastStatus } from '../helpers/TableColumns'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const DispatchSheet = (props) => {
	const {
		data: { collection, orders, type },
	} = props
	let SheetData = []
	const getHeaders = () => {
		let Headers = []
		if (collection === 'import') {
			Headers = [
				{ value: 'orderNumber', label: 'Order Number' },
				{ value: 'businessCode', label: 'Business Code' },
				{ value: 'documentNumber', label: 'BL Number' },
				{ value: 'shippingLine', label: 'Shipping Line' },
				{ value: 'driverVehicleRegistration', label: 'Vehicle Number' },
				{ value: 'driverPhone', label: 'Driver Phone Number' },
				{ value: 'containerNumber', label: 'Container Number' },
				{ value: 'containerDimensions', label: 'Container Dimensions' },
				{ value: 'movementType', label: 'Movement Type' },
				{ value: 'movedWeight', label: 'Moved Weight (Kilos)' },
				{ value: 'totalWeight', label: 'Total Weight (Kilos)' },
				{ value: 'movedCount', label: 'Moved Bales' },
				{ value: 'totalCount', label: 'Total Bales' },
				{ value: 'biltyNumber', label: 'Bilty Number' },
				{ value: 'doStatus', label: 'DO Status' },
				{ value: 'returnDT', label: 'Empty Dropoff Deadline' },
				{ value: 'orderStatus', label: 'Order Status' },
				{ value: 'driverLastStatus', label: 'Driver Status' },
				{ value: 'driverLastSubStatus', label: 'Driver Location' },
				{ value: 'driverLastSubStatusDT', label: 'Last Location Update Time' },
				{ value: 'reachedLoading', label: 'Vehicle Reached Port At' },
				{ value: 'leftLoading', label: 'Vehicle Left Port At' },
				{ value: 'reachedDropoff', label: 'Vehicle Reached Dropoff Point At' },
				{ value: 'subStatusStarted', label: 'Unoading Started At' },
				{ value: 'subStatusCompleted', label: 'Unoading Completed At' },
				{ value: 'reachedTerminal', label: 'Vehicle Empty Container Terminal At' },
				// { value: 'orderCompletionDate', label: 'Order Completion Date' },
				{ value: 'freightType', label: 'Cargo Type' },
				{ value: 'freightWeight', label: 'Cargo Weight' },
				{ value: 'loadingPoint', label: 'Loading Point' },
				{ value: 'dropoffPoint', label: 'Dropoff Point' },
				{ value: 'terminal', label: 'Empty Container Dropoff' },
			]
		} else if (collection === 'export') {
			Headers = [
				{ value: 'orderNumber', label: 'Order Number' },
				{ value: 'businessCode', label: 'Business Code' },
				{ value: 'loadingDate', label: 'Loading Date' },
				{ value: 'driverVehicleRegistration', label: 'Vehicle Number' },
				{ value: 'driverPhone', label: 'Driver Phone Number' },
				{ value: 'containerNumber', label: 'Container Number' },
				{ value: 'containerDimensions', label: 'Container Dimensions' },
				{ value: 'movementType', label: 'Movement Type' },
				{ value: 'freightWeight', label: 'Cargo Weight' },
				{ value: 'documentNumber', label: 'CRO Number' },
				{ value: 'shippingLine', label: 'Shipping Line' },
				{ value: 'terminal', label: 'Release Terminal Location' },
				{ value: 'freightType', label: 'Cargo Type' },
				{ value: 'biltyNumber', label: 'Bilty Number' },
				{ value: 'loadingPoint', label: 'Loading Point' },
				{ value: 'dropoffPoint', label: 'Dropoff Point' },
				{ value: 'orderStatus', label: 'Order Status' },
				{ value: 'driverLastStatus', label: 'Driver Status' },
				{ value: 'driverLastSubStatus', label: 'Driver Location' },
				{ value: 'driverLastSubStatusDT', label: 'Last Location Update Time' },
				{ value: 'reachedTerminal', label: 'Vehicle Reached Container Release Terminal At' },
				{ value: 'reachedLoading', label: 'Vehicle Reached Loading Point At' },
				{ value: 'subStatusStarted', label: 'Loading Started At' },
				{ value: 'subStatusCompleted', label: 'Loading Completed At' },
				{ value: 'leftLoading', label: 'Vehicle Left Loading Point At' },
				{ value: 'reachedDropoff', label: 'Vehicle Reached Port At' },
				// { value: 'orderCompletionDate', label: 'Order Completion Date' },
			]
		} else if (collection === 'longHaul') {
			Headers = [
				{ value: 'orderNumber', label: 'Order Number' },
				{ value: 'businessName', label: 'Client Name' },
				{ value: 'loadingDate', label: 'Loading Date' },
				{ value: 'movementType', label: 'Movement Type' },
				{ value: 'driverVehicleRegistration', label: 'Vehicle Number' },
				{ value: 'driverPhone', label: 'Driver Phone Number' },
				{ value: 'vehicleType', label: 'Vehicle Type' },
				{ value: 'netWeightAtLoadingPoint', label: 'Net Weight At Loading Point' },
				{ value: 'netWeightAtDropoffPoint', label: 'Net Weight At Dropoff Point' },
				{ value: 'orderStatus', label: 'Order Status' },
				{ value: 'driverLastStatus', label: 'Driver Status' },
				{ value: 'driverLastSubStatus', label: 'Driver Location' },
				{ value: 'driverLastSubStatusDT', label: 'Last Location Update Time' },
				{ value: 'biltyNumber', label: 'Bilty Number' },
				{ value: 'loadingPoint', label: 'Loading Point' },
				{ value: 'dropoffPoint', label: 'Dropoff Point 1' },
				{ value: 'dropoffPoint2', label: 'Dropoff Point 2' },
				{ value: 'dropoffPoint3', label: 'Dropoff Point 3' },
				{ value: 'freightType', label: 'Cargo Type' },
				{ value: 'freightWeight', label: 'Cargo Weight' },

				{ value: 'reachedLoading', label: 'Vehicle Reached Loading Point At' },
				{ value: 'subStatusStarted', label: 'Loading Started At' },
				{ value: 'subStatusCompleted', label: 'Loading Completed At' },
				{ value: 'leftLoading', label: 'Vehicle Left Loading Point At' },

				{ value: 'reachedDropoff1', label: 'Vehicle Reached Dropoff Point 1 At' },
				{ value: 'subStatusStarted1', label: 'Unloading on Dropoff 1 Started At' },
				{ value: 'subStatusCompleted1', label: 'Unloading on Dropoff 1 Completed At' },
				{ value: 'leftDropoff1', label: 'Vehicle Left Dropoff Point 1 At' },

				{ value: 'reachedDropoff2', label: 'Vehicle Reached Dropoff Point 2 At' },
				{ value: 'subStatusStarted2', label: 'Unloading on Dropoff 2 Started At' },
				{ value: 'subStatusCompleted2', label: 'Unloading on Dropoff 2 Completed At' },
				{ value: 'leftDropoff2', label: 'Vehicle Left Dropoff Point 2 At' },

				{ value: 'reachedDropoff3', label: 'Vehicle Reached Dropoff Point 3 At' },
				{ value: 'subStatusStarted3', label: 'Unloading on Dropoff 3 Started At' },
				{ value: 'subStatusCompleted3', label: 'Unloading on Dropoff 3 Completed At' },

				// { value: 'orderCompletionDate', label: 'Order Completion Date' },
			]
		}
		return Headers
	}

	const createSheetData = () => {
		let sheetOrders = []
		if (type === 'bundled') {
			sheetOrders = orders
				.map(
					(order) =>
						order?.movements &&
						Object.keys(order?.movements)
							.map((key) =>
								Object.values(order?.movements?.[`${key}`])
									?.map((movement) =>
										movement?.subOrders?.map((subOrder) => {
											return {
												...movement.job,
												...subOrder,
												subMovementType: key,
												loading: order.loading,
												dropOff: order.dropoff,
												releaseTerminal: order.releaseTerminal,
												businessCode: order.businessCode,
												documentNumber: order?.documentNumber,
												loadingDT: order?.loadingDT,
												shippingLine: order?.shippingLine,
												doStatus: order?.doStatus,
												vehicleInfo: order?.vehicleInfo,
											}
										})
									)
									.flat()
							)
							.flat()
				)
				?.flat()
				.sort((a, b) => a.subOrderNumber.localeCompare(b.subOrderNumber))
		} else {
			sheetOrders = orders.selectedRows
				?.map((order) =>
					order?.subOrders?.map((subOrder) => {
						return {
							...order.job,
							...subOrder,
							loading: orders?.selectedRecord?.loading,
							dropOff: orders?.selectedRecord?.dropoff,
							releaseTerminal: orders?.selectedRecord?.releaseTerminal,
							businessCode: orders?.selectedRecord?.businessCode,
							documentNumber: orders?.selectedRecord?.documentNumber,
							loadingDT: orders?.selectedRecord?.loadingDT,
							shippingLine: orders?.selectedRecord?.shippingLine,
							doStatus: orders?.selectedRecord?.doStatus,
							vehicleInfo: orders?.selectedRecord?.vehicleInfo,
						}
					})
				)
				.flat()
				.sort((a, b) => a.subOrderNumber.localeCompare(b.subOrderNumber))
		}

		sheetOrders?.forEach((order) => {
			let RowData = {}
			const bilties = order?.bilty?.filter((bilty) => (order?.type === 'Import' ? bilty?.documentNumber === order?.documentNumber : true))
			const FirstLoading = order.loading && Object.values(order.loading)?.sort((a, b) => b.number - a.number)?.[0]
			const Dropoffs = order.dropOff && Object.values(order.dropOff)?.sort((a, b) => b.number - a.number)
			// const releaseTerminal = order?.releaseTerminal?.[0]
			const FirstDropoff = Dropoffs?.[0]
			const FirstContainer = order.containerInfo && order.containerInfo
			const movStatus = order?.driverStatusHistories && Object.values(order?.driverStatusHistories).flat()
			// console.log(movStatus)
			const { lastStatus, lastSubStatus, lastLegitSubStatus } = getDriverLastStatus(movStatus)

			RowData.biltyNumber = bilties?.[0]?.biltyNumber || ''
			RowData.businessCode = order.businessCode
			RowData.containerDimensions = ContainerDimensionsEnum[FirstContainer?.dimension]?.long
			RowData.containerNumber = FirstContainer?.containerNumber
			RowData.documentNumber = order?.documentNumber
			RowData.driverPhone = order.Driver?.phoneNumber || ''
			RowData.driverVehicleRegistration = order.Vehicle?.registrationNumber || ''
			RowData.dropoffPoint = FirstDropoff?.formattedAddress
			RowData.freightType = FirstContainer?.freightType
			RowData.freightWeight = FirstContainer?.freightWeight || 0
			RowData.loadingDate = get24HourDate(order?.loadingDT)
			RowData.loadingPoint = FirstLoading?.formattedAddress
			RowData.orderCompletionDate =
				order.orderStatus?.status === 'orderCompleted' ? get24HourDate(order.orderStatus.time || order.orderStatus.timestamp) : ''
			RowData.orderNumber = order.subOrderNumber || order.orderNumber
			RowData.orderStatus = OrderStatusEnum[order.orderStatus?.status]?.long
			RowData.shippingLine = order?.shippingLine
			RowData.terminal = order?.releaseTerminal?.[0].formattedAddress
			RowData.driverLastStatus = ReadableString(lastStatus?.status)
			RowData.driverLastSubStatus = ReadableString(lastLegitSubStatus?.status || lastSubStatus?.status)
			RowData.driverLastSubStatusDT = get24HourDate(lastLegitSubStatus?.timestamp || lastSubStatus?.timestamp)

			const driverStatuses = getDriverStatuses(movStatus)
			// const driverStatusesValues = driverStatuses && Object.values(driverStatuses)
			const { reachedLoading, leftLoading, reachedDropoff, reachedTerminal, subStatusStarted, subStatusCompleted } = driverStatuses
			// console.log(driverStatuses)
			RowData.reachedLoading = get24HourDate(reachedLoading?.timestamp)
			RowData.leftLoading = get24HourDate(leftLoading?.timestamp)
			// console.log('reachedDropoff', get24HourDate(reachedDropoff?.timestamp), 'reachedTerminal', get24HourDate(reachedTerminal?.timestamp))
			RowData.reachedDropoff = get24HourDate(reachedDropoff?.timestamp)
			RowData.reachedTerminal = get24HourDate(reachedTerminal?.timestamp)
			// console.log(reachedTerminal)
			RowData.subStatusStarted = get24HourDate(subStatusStarted?.timestamp)
			RowData.subStatusCompleted = get24HourDate(subStatusCompleted?.timestamp)
			RowData.movementType =
				collection === 'longHaul' && order?.preferences?.lotOrder
					? 'Lot'
					: collection === 'longHaul' && !order?.preferences?.lotOrder
					? 'Non-Lot'
					: order?.subMovementType

			if (collection === 'import') {
				RowData.movedCount = order?.movedUnits !== 'Kilos' ? order?.movementCount : ''
				RowData.totalCount = order?.movedUnits !== 'Kilos' ? order?.totalCount : ''
				RowData.movedWeight = order?.movedUnits === 'Kilos' ? order?.movementCount : ''
				RowData.totalWeight = order?.movedUnits === 'Kilos' ? order?.totalCount : ''
				RowData.doStatus = ReadableString(order?.doStatus)
				RowData.returnDT = order?.freeDays && get24HourDate(order?.freeDays)
			} else if (collection === 'longHaul') {
				RowData.vehicleType = `${order.vehicleInfo?.vehicleType} - ${order.vehicleInfo?.vehicleSubtype}`
				RowData.dropoffPoint2 = Dropoffs?.[1]?.formattedAddress
				RowData.dropoffPoint3 = Dropoffs?.[2]?.formattedAddress

				const driverStatuses = getBulkDriverStatuses(movStatus)
				const driverStatusesValues = driverStatuses && Object.values(driverStatuses)
				driverStatuses && Object.keys(driverStatuses)?.forEach((key, index) => (RowData[key] = get24HourDate(driverStatusesValues[index]?.timestamp)))
			}
			if (SheetData.length === sheetOrders.length) SheetData = [RowData]
			else SheetData.push(RowData)
		})
	}

	const getBulkDriverStatuses = (driverStatusHistory) => {
		const reachedDropoff1 = getDriverStatusTime(driverStatusHistory, 'driverReachedDropoffLocation', 0)
		const subStatusStarted1 = getDriverStatusTime(reachedDropoff1?.DriverStatusJourneys, 'unloadingInProcess')
		const subStatusCompleted1 = getDriverStatusTime(reachedDropoff1?.DriverStatusJourneys, 'unloadingCompleted')

		const leftDropoff1 = getDriverStatusTime(driverStatusHistory, 'driverMovingToDropoffLocation', 1)
		const reachedDropoff2 = getDriverStatusTime(driverStatusHistory, 'driverReachedDropoffLocation', 1)
		const subStatusStarted2 = getDriverStatusTime(reachedDropoff2?.DriverStatusJourneys, 'unloadingInProcess')
		const subStatusCompleted2 = getDriverStatusTime(reachedDropoff2?.DriverStatusJourneys, 'unloadingCompleted')

		const leftDropoff2 = getDriverStatusTime(driverStatusHistory, 'driverMovingToDropoffLocation', 2)
		const reachedDropoff3 = getDriverStatusTime(driverStatusHistory, 'driverReachedDropoffLocation', 2)
		const subStatusStarted3 = getDriverStatusTime(reachedDropoff3?.DriverStatusJourneys, 'unloadingInProcess')
		const subStatusCompleted3 = getDriverStatusTime(reachedDropoff3?.DriverStatusJourneys, 'unloadingCompleted')
		return {
			reachedDropoff1,
			subStatusStarted1,
			subStatusCompleted1,
			leftDropoff1,
			reachedDropoff2,
			subStatusStarted2,
			subStatusCompleted2,
			leftDropoff2,
			reachedDropoff3,
			subStatusStarted3,
			subStatusCompleted3,
		}
	}

	const getDriverStatuses = (driverStatusHistory) => {
		// console.log({ driverStatusHistory })
		const reachedLoading = getDriverStatusTime(driverStatusHistory, 'driverReachedPickupLocation')
		const leftLoading = getDriverStatusTime(driverStatusHistory, 'driverMovingToDropoffLocation')
		const reachedDropoff = getDriverStatusTime(driverStatusHistory, 'driverReachedDropoffLocation')
		const reachedTerminal = getDriverStatusTime(driverStatusHistory, 'driverReachedContainerDropoffLocation')
		// console.log(collection)
		// console.log(reachedTerminal)
		const subStatusStarted = getDriverStatusTime(
			collection === 'import' ? reachedDropoff?.DriverStatusJourneys : reachedLoading?.DriverStatusJourneys,
			collection === 'import' ? 'unloadingInProcess' : 'loadingInProcess'
		)
		const subStatusCompleted = getDriverStatusTime(
			collection === 'import' ? reachedDropoff?.DriverStatusJourneys : reachedLoading?.DriverStatusJourneys,
			collection === 'import' ? 'unloadingCompleted' : 'loadingCompleted'
		)
		// console.log({ reachedLoading, leftLoading, reachedDropoff, reachedTerminal, subStatusStarted, subStatusCompleted })
		return { reachedLoading, leftLoading, reachedDropoff, reachedTerminal, subStatusStarted, subStatusCompleted }
	}
	const getDriverStatusTime = (statuses, searchStatus, index) => {
		return statuses?.length > 0 && statuses?.filter((status) => status.status === searchStatus)?.[index || 0]
	}
	// console.log(orders?.selectedRecord)

	return (
		<ExcelFile
			element={
				<Button
					className={`border-none border-0 ${
						orders?.length === 0 || orders?.selectedRows?.length === 0 ? '' : 'bg-[#00800b] text-white hover:bg-[#00800b] hover:text-white'
					}  w-44 !rounded `}
					style={{ height: 40 }}
					disabled={orders?.length === 0 || orders?.selectedRows?.length === 0}
					onClick={() => createSheetData()}
				>
					<div className='flex flex-row items-center justify-between'>
						<div className='font-extrabold !text-[14px]'>Download File</div>
						<Icon path={mdiFileDownloadOutline} size={0.9} />
					</div>
				</Button>
			}
		>
			<ExcelSheet data={SheetData} name={'Sheet'}>
				{getHeaders()?.map((header, index) => {
					// SheetData.length && console.log(SheetData)
					return <ExcelColumn key={index} value={header.value} label={header.label} />
				})}
			</ExcelSheet>
		</ExcelFile>
	)
}

export default DispatchSheet

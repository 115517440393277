// import { FormRender } from './FormRenderer'

export const LoginFormSchema = (props) => {
	// console.log(props)
	const Schema = [
		{
			type: 'input',
			label: 'Email',
			name: 'email',
			inputType: 'text',
			required: true,
			message: 'Email is required.',
			addonAfter: '@bridgelinxpk.com',
			disabled: props.loading
		},
	]
	if (!props.forgotPassword) {
		Schema.push({
			type: 'password',
			label: 'Password',
			name: 'password',
			inputType: 'password',
			required: true,
			message: 'Password is required.',
			disabled: props.loading
		})
	}
	return Schema
}

export const CommentBoxSchema = () => [
	{
		type: 'input',
		label: 'New Comment',
		name: 'comment',
		inputType: 'textArea',
		rows: 4,
	},
]

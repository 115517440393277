import { Spin, Tooltip } from 'antd'
import moment from 'moment'
import Icon from '@mdi/react'
import { mdiInformationOutline } from '@mdi/js'

import { firestore } from '../firebase/firebaseConfig'

import { GraphQLEnums, ProjectId } from './Enums'

import {
	GroupedOrderDetailsColumn,
	DocumentNumber,
	LoadingDate,
	BiltyDate,
	ShippingLine,
	VehiclesArranged,
	DoStatus,
	Dropoff,
	Route,
	Options,
	ClientName,
	SubOrderDoStatus,
	TrackingStatus,
} from '../helpers/TableColumns'
import { ExpandedOrderDetailsColumn, ContainerDetails, LinkedContainers, DriverDetails, RouteProgress } from '../helpers/TableColumns'

import { PagesEnum, OrderStatusEnum } from './Enums'

/* ============================================================================================== */
/* ===================================== UTILITY FUNCTIONS ====================================== */
/* ============================================================================================== */
export const check = (Param) => Param !== null && Param !== undefined
export const EMAIL_REGEX = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
export const formatPhoneNumber = (phoneNumber) => phoneNumber?.replace(/^(\+92)(\d{3})(\d{7})$/, '$1 $2 $3')
export const checkArray = (Param) => !!Param && Param.constructor === Array
export const currentCollection = (path) => path.split('/')[1]
export const selectedRecordId = (path) => path.split('/')[2]
export const ReadableString = (string) => string && string.replace(/([A-Z])/g, ' $1')
export const Loader = () => <Spin className='Spinner' tip={<p className='BoldFont FontSize16 PrimaryColor MarginTop'>Loading...</p>} size='large' />
export const Capitalize = (string) => string[0].toUpperCase() + string.slice(1)
export function range(start, end) {
	let array = new Array(end - start + 1)
	for (let i = 0; i < end - start + 1; i++) array[i] = start + i
	return array
}
export const twoDigit = (number) => (number > 0 && number < 10 ? `0${number}` : number)
export const get12HourDate = (time) => (time ? moment(time).format('DD-MM-YYYY HH:mm A') : '')
export const get24HourDate = (time) => (time ? moment(time).format('DD-MM-YYYY HH:mm') : '')

export const groupByProperty = (array, property) => {
	return array.reduce((previous, current) => {
		if (!previous[current[property]]) {
			previous[current[property]] = []
		}
		previous[current[property]].push(current)
		return previous
	}, {})
}

export const defaultDates = () => {
	const format = 'YYYY-MM-DD'
	const date = moment().startOf('day').format('YYYY-MM-DD')
	const startDate = moment(moment(date, format).subtract(15, 'days').format(format))
	const endDate = moment(moment().startOf('day').format('YYYY-MM-DD'))
	return [startDate, endDate]
}

export const startOfDay = moment().startOf('day').format('x')
export const endOfDay = moment().endOf('day').format('x')

export const RemoveDuplicates = (array, key) => {
	return array.filter((value, index) => index === array.findIndex((obj) => obj[key] === value[key]))
}

/* ============================================================================================== */
/* ====================================== UTILITY DISPLAYS ====================================== */
/* ============================================================================================== */
export const StatusPill = (status) => (
	<p
		className='StatusPill'
		style={{
			background: OrderStatusEnum[status]?.background,
			color: OrderStatusEnum[status]?.color,
			border: ` 1px solid ${OrderStatusEnum[status]?.color}`,
		}}
	>
		{OrderStatusEnum[status]?.long}
	</p>
)

export const SubOrderNoPill = (type, subOrderNo, jobNumber) => (
	<p
		key={subOrderNo}
		className={jobNumber?'StatusPill PrimaryColor PillPadding QuarterMarginRight':'QuarterMarginRight BoldFont PrimaryColor'}
		style={jobNumber?{
			border: ` 1px solid #0066CC`,
		}:{}}
	>
	{PagesEnum[type === 'LongHaul' ? 'longHaul' : type?.toLowerCase()]?.initial}-{subOrderNo}
	</p>
) //prettier-ignore

export const ModalHeader = (title, description) => (
	<div className='FlexColumn'>
		<p className='BoldFont PrimaryColor'>{title}</p>
		{description && <p className='FontSize12 GrayColor'>{description}</p>}
	</div>
)

export const getOrders = async (collection, orderIds) => {
	const bulkPromises = orderIds?.map((orderId) => firestore.collection(collection).doc(orderId).get())
	const result = await Promise.all(bulkPromises)
	const orders = result.map((doc) => doc.data())
	return orders
}

export const updateAtom = (props) => {
	const { type, prev, subscriptionData, setAtom } = props

	const segmentQuery = GraphQLEnums[`${type}`]?.fetch?.key
	const segmentSubscription = GraphQLEnums[`${type}`]?.subscription?.key
	const order = subscriptionData?.data?.[segmentSubscription]
	let existing = {}
	let orders = []

	// console.log('order', order)
	Object.assign(existing, prev)
	Object.assign(orders, existing[segmentQuery])

	const index = orders.findIndex((oldOrder) => oldOrder?._id === order?._id)
	if (index >= 0) {
		orders[index] = order
		// console.log('overWriting', orders)
	} else {
		orders.push(order)
		// console.log('addingNew', orders)
	}

	existing[segmentQuery] = orders
	setAtom(orders)
	return existing
}

export const getTableColumns = (setSelectedRecord, selectedRecord, modalData, setModalData, selectedTab) => {
	return {
		import: {
			groupedView: [
				GroupedOrderDetailsColumn({
					collection: 'import',
					setSelectedRecord,
					modalData,
					setModalData,
				}),
				ClientName,
				DocumentNumber('import'),
				LoadingDate,
				ShippingLine,
				VehiclesArranged,
				DoStatus,
				Options({ modalData, setModalData }),
			],
			detailedView:
				selectedTab === 'bulkOrders'
					? [
							ExpandedOrderDetailsColumn,
							LinkedContainers(selectedRecord),
							DriverDetails,
							BiltyDate,
							RouteProgress('import'),
							TrackingStatus({ modalData, setModalData }),
					  ]
					: [
							ExpandedOrderDetailsColumn,
							ContainerDetails,
							DriverDetails,
							BiltyDate,
							SubOrderDoStatus,
							RouteProgress('import'),
							TrackingStatus({ modalData, setModalData }),
					  ],
		},
		export: {
			groupedView: [
				GroupedOrderDetailsColumn({
					collection: 'export',
					setSelectedRecord,
					modalData,
					setModalData,
				}),
				ClientName,
				DocumentNumber('export'),
				LoadingDate,
				ShippingLine,
				VehiclesArranged,
				Dropoff,
				Options({ modalData, setModalData }),
			],
			detailedView: [
				ExpandedOrderDetailsColumn,
				ContainerDetails,
				DriverDetails,
				BiltyDate,
				RouteProgress('export'),
				TrackingStatus({ modalData, setModalData }),
			],
		},
		longHaul: {
			groupedView: [
				GroupedOrderDetailsColumn({
					collection: 'longHaul',
					setSelectedRecord,
					modalData,
					setModalData,
				}),
				ClientName,
				LoadingDate,
				Route,
				VehiclesArranged,
				Options({ modalData, setModalData }),
			],
			detailedView: [ExpandedOrderDetailsColumn, DriverDetails, BiltyDate, RouteProgress('longHaul'), TrackingStatus({ modalData, setModalData })],
		},
	}
}

export const PortalPill = () => (
	<div className='FlexRow Margin BorderRadiusMed QuarterPaddingTop QuarterPaddingBottom HalfPaddingLeft HalfPaddingRight PSBackground'>
		<div className='WhiteColor' style={{ lineHeight: '16px' }}>
			{ProjectId}
		</div>
	</div>
)
// export const onAuthStateChanged = async (user) => {
// 	if (user) {
// 		const token = await user.getIdToken()
// 		console.log(token)
// 		localStorage.setItem('token', JSON.stringify({ token }))
// 		sessionStorage.setItem('token', JSON.stringify({ token }))
// 	}
// }

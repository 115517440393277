import { gql } from '@apollo/client'

import { CONTACT_FIELDS, CORPORATE_FIELDS, CORPORATE_LOCATION_FIELDS, ORDER_FIELDS, USER_FIELDS } from './fragments'

export const GET_USER = gql`
	${USER_FIELDS}
	query Users($where: UserWhere) {
		users(where: $where) {
			...UserFields
			UserType {
				userType
			}
		}
	}
`

export const GET_ALL_CORPORATES = gql`
	${CORPORATE_FIELDS}
	${CORPORATE_LOCATION_FIELDS}
	${CONTACT_FIELDS}
	query Corporates {
		corporates {
			...CorporateFields
			sector
			updateEmail
			corporateId
			freightType
			CorporateLocations {
				...CorporateLocationFields
			}
			clearingAgents {
				...ContactFields
			}
		}
	}
`

export const GET_ALL_ORDERS = gql`
	${ORDER_FIELDS}
	query Orders($where: OrderWhere, $options: OrderOptions, $biltyOptions2: BiltyOptions) {
		orders(where: $where, options: $options) {
			...OrderFields
		}
	}
`

export const ADVANCED_SEARCH = gql`
	${ORDER_FIELDS}
	query Orders($where: OrderWhere, $options: OrderOptions, $biltyOptions2: BiltyOptions) {
		orders(where: $where, options: $options) {
			...OrderFields
		}
	}
`
export const GET_ALL_ORDERS_IN_RANGE = gql`
	${ORDER_FIELDS}
	query Orders($where: OrderWhere, $options: OrderOptions) {
		orders(where: $where, options: $options) {
			...OrderFields
		}
	}
`

import ReportLayoutEnums from './ReportLayoutEnums'

const ReportBody = (props) => {
	const { segment, orderCategory, reportType, processedMovements, documentNumber } = props

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					flexDirection: 'row',
					flexWrap: 'wrap',
					...(ReportLayoutEnums[segment][orderCategory][reportType]?.info?.length !== 0 && { padding: '10px 20px' }),
				}}
			>
				{ReportLayoutEnums[segment][orderCategory][reportType]?.info?.map((row) => (
					<>
						{row.render({
							props,
						})}
					</>
				))}
			</div>
			<table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: 0 }}>
				<thead>
					<tr style={{ height: '62px', background: '#F2F2F2' }}>
						{ReportLayoutEnums[segment][orderCategory][reportType]?.table?.map((column) => (
							<th style={{ padding: '20px 10px 20px 20px', fontSize: '11px', textAlign: 'left', width: `${column?.widthClassname ? column.widthClassname : ''}` }}>
								{column.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{processedMovements?.length > 0 &&
						processedMovements?.map((movement) => {
							return (
								<>
									<tr
										key={movement?._id}
										style={{
											...(ReportLayoutEnums[segment][orderCategory][reportType]?.expandedRow?.length === 0 && { borderBottom: '1px solid #D0D0D0' }),
											height: '67px',
										}}
									>
										{ReportLayoutEnums[segment][orderCategory][reportType]?.table?.map((column) => (
											<td style={{ padding: '20px 10px 20px 20px' }}>{column.render({ movement, segment, documentNumber })}</td>
										))}
									</tr>
									{ReportLayoutEnums[segment][orderCategory][reportType]?.expandedRow?.length !== 0 && (
										<tr style={{ background: '#F3F3F3', borderBottom: '1px solid #D0D0D0' }}>
											<td colSpan={ReportLayoutEnums[segment][orderCategory][reportType]?.table?.length} style={{ height: '35px' }}>
												<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
													{ReportLayoutEnums[segment][orderCategory][reportType]?.expandedRow?.map((row) => (
														<>{row.render({ movement, segment })}</>
													))}
												</div>
											</td>
										</tr>
									)}
								</>
							)
						})}
				</tbody>
			</table>
		</>
	)
}

export default ReportBody

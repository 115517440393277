import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GraphQLEnums } from '../helpers/Enums'
import { updateAtom } from '../helpers/Functions'

import { useSetRecoilState } from 'recoil'
import { importOrdersAtom } from '../recoil/atoms'

export const useFetch = ({ type, atomKey, variables = {}, subscriptionVariables = {}, pageInfo }) => {
	const setAtom = useSetRecoilState(GraphQLEnums[`${type}`]?.atoms?.[`${atomKey}`])
	const variablesPacket = Object.keys(variables).length > 0 && GraphQLEnums[`${type}`].fetch?.getVariables(variables)
	const paginationVariables = pageInfo
		? {
				...variablesPacket,
				options: {
					...variablesPacket?.options,
					limit: pageInfo?.pageSize,
					offset: pageInfo?.pageSize * (pageInfo?.pageNo - 1),
				},
		  }
		: null

	const { data, loading, error, fetchMore, subscribeToMore } = useQuery(GraphQLEnums[`${type}`].fetch?.name, {
		variables: pageInfo ? paginationVariables : variables && variablesPacket,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (pageInfo) {
			fetchMore(variables)
		}
	}, [pageInfo]) // eslint-disable-line

	useEffect(() => {
		// console.log(subscriptionVariables)
		if (GraphQLEnums[`${type}`].subscription?.name) {
			return subscribeToMore({
				document: GraphQLEnums[`${type}`]?.subscription?.name,
				variables: subscriptionVariables,
				updateQuery: (prev, { subscriptionData }) => updateAtom({ prev, subscriptionData, type, setAtom }),
			})
		}
	}, [subscriptionVariables]) // eslint-disable-line

	useEffect(() => {
		if (error) {
			throw error
		}

		data && setAtom(GraphQLEnums[`${type}`]?.fetch?.key?.reduce((prev, curr) => prev?.[`${curr}`], data))
	}, [data, error]) // eslint-disable-line

	return loading
}
export const useLazyFetch = ({ type, atomKey, storageKey }) => {
	const setAtom = useSetRecoilState(atomKey ? GraphQLEnums[`${type}`]?.atoms?.[`${atomKey}`] : importOrdersAtom)

	const [fetchData, { data, loading, error }] = useLazyQuery(GraphQLEnums[`${type}`].fetch?.name, {
		fetchPolicy: 'network-only',
		nextFetchPolicy: 'network-only',
	})
	useEffect(() => {
		if (error) {
			throw error
		} else if (data) {
			let processedData = GraphQLEnums[`${type}`]?.fetch?.key?.reduce((prev, curr) => prev?.[`${curr}`], data)
			storageKey && localStorage.setItem(storageKey, JSON.stringify(processedData))

			// console.log('dataload', { processedData })
			atomKey && setAtom(processedData)
		}
	}, [data, error]) // eslint-disable-line

	return [fetchData, loading, data]
}

export const useMutate = ({ type, action }) => {
	const [setData, { data, loading, error }] = useMutation(GraphQLEnums[type]?.[action].name)
	const [processedData, setProcessedData] = useState(data)
	useEffect(() => {
		if (error) {
			throw error
		} else if (data) {
			setProcessedData(GraphQLEnums[type]?.[action]?.key?.reduce((prev, curr) => prev?.[`${curr}`], data || []))
		}
	}, [data, error]) // eslint-disable-line

	return [setData, loading, processedData]
}

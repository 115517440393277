import Icon from '@mdi/react'
import moment from 'moment'
import { Popover, Tabs } from 'antd'
import { mdiCalendar, mdiMapMarkerDistance, mdiTruckOutline, mdiInformationOutline } from '@mdi/js'
import { PagesEnum } from '../../helpers/Enums'
import LocationsTimeline from './LocationsTimeline'

const VehicleArrangedPopover = (importShortHaulCount, bulkVehicleCount, vehicleArranged, vehicleTotal) => {
	const ShowVehicleCount = (heading, arranged, total, classes) => (
		<>
			<p className={`BoldFont ${classes}`}>{heading}</p>
			<p className='BoldFont'>
				{arranged}
				<span className='RegularFont'> out of </span>
				{total}
			</p>
		</>
	)
	return (
		<div className='PaddingExceptTop NoWrap'>
			{ShowVehicleCount('Total', vehicleArranged, vehicleTotal)}
			{ShowVehicleCount('Import', importShortHaulCount?.arranged, importShortHaulCount?.total, 'PrimaryColor HalfMarginTop')}
			{ShowVehicleCount('Bulk', bulkVehicleCount?.arranged, bulkVehicleCount?.total, 'PrimaryColor HalfMarginTop')}
		</div>
	)
}

export const VehicleArranged = (record, showDescription) => {
	const { orderCategory, movements, preferences } = record

	const jobs =
		movements &&
		Object.values(movements)
			.map((movement) => Object.values(movement))
			.flat()
			?.map((movement) =>
				movement?.job?.matched === 'Matched' || movement?.job?.matched === 'PartialMatched'
					? movement
					: movement?.subOrders?.map((subOrder) => ({
							job: movement.job,
							subOrders: [subOrder],
					  }))
			)
			?.flat()
	let vehicleTotal = jobs?.length || 0

	let vehicleArranged = jobs?.filter((job) => job?.job?.matched === 'Matched' || job?.job?.matched === 'PartialMatched')?.length || 0

	const importShortHaulCount = { arranged: 0, total: 0 }
	const bulkVehicleCount = { arranged: 0, total: 0 }

	if (movements.hasOwnProperty('ImportShortHaul') && movements.hasOwnProperty('LongHaul')) {
		// console.log(movements, preferences.numberFlatbeds)
		const importShortHaulVehiclesAssigned = Object.keys(movements['ImportShortHaul']).filter((id) => id !== 'undefined')
		const longHaulVehiclesAssigned = Object.keys(movements['LongHaul']).filter((id) => id !== 'undefined')
		let importShortHaulVehiclesUnAssigned = Object.keys(movements?.ImportShortHaul).filter((id) => id === 'undefined')
		if (importShortHaulVehiclesUnAssigned.length > 0) {
			importShortHaulVehiclesUnAssigned = movements?.ImportShortHaul?.undefined?.subOrders
		}
		let longHaulVehiclesUnAssigned = Object.keys(movements?.LongHaul).filter((id) => id === 'undefined')
		let longHaulVehiclestotal = 0
		if (longHaulVehiclesUnAssigned.length > 0) {
			if (longHaulVehiclesAssigned >= preferences.numberFlatbeds) {
				longHaulVehiclestotal = longHaulVehiclesAssigned.length + movements?.LongHaul?.undefined?.subOrders.length
				// console.log(longHaulVehiclestotal)
			} else {
				longHaulVehiclestotal = preferences.numberFlatbeds
			}
		} else {
			if (!movements.LongHaul?.undefined) longHaulVehiclestotal = longHaulVehiclesAssigned.length
		}

		vehicleTotal = importShortHaulVehiclesAssigned.length + importShortHaulVehiclesUnAssigned.length + longHaulVehiclestotal
		importShortHaulCount.arranged = importShortHaulVehiclesAssigned.length
		importShortHaulCount.total = importShortHaulVehiclesUnAssigned.length + importShortHaulVehiclesAssigned.length
		bulkVehicleCount.arranged = longHaulVehiclesAssigned.length
		bulkVehicleCount.total = longHaulVehiclestotal
	}
	// console.log(orderCategory)
	return (
		<div className='FlexColumn'>
			<div className='FlexRow GHBackground Pill'>
				<div
					className='FullHeight FlexRow QuarterPaddingLeftRight BorderRadiusCircle'
					style={{
						backgroundColor: vehicleArranged === 0 ? '#FF0000' : vehicleArranged === vehicleTotal ? '#00800B' : '#FFC700',
					}}
				>
					<Icon path={mdiTruckOutline} size={1} className='WhiteColor' />
				</div>
				<p className='BoldFont QuarterPaddingLeft HalfPaddingRight'>
					{vehicleArranged}
					<span className='RegularFont'> out of </span>
					{vehicleTotal}
				</p>
				{['shifting', 'destuffing'].includes(orderCategory) && (
					<Popover
						title='Vehicle Breakdown'
						placement='bottom'
						content={VehicleArrangedPopover(importShortHaulCount, bulkVehicleCount, vehicleArranged, vehicleTotal)}
					>
						<div className='FullHeight FlexRow QuarterPaddingRight '>
							<Icon path={mdiInformationOutline} size={1} className='GrayColor ' />
						</div>
					</Popover>
				)}
			</div>
			{showDescription && ['shifting', 'destuffing'].includes(orderCategory) && <p className='FontSize12'>Click to view import and bulk details</p>}
		</div>
	)
}

const OrderDetailsHeader = (collection, record, setSelectedTab) => {
	const { orderNumber, businessCode, docNumber, loadingDT, orderCategory, refNumber } = record
	const containerInfo = record?.subOrders?.[0]?.containerInfo && Object.values(record?.subOrders?.[0]?.containerInfo)
	const terminal = containerInfo?.[0]?.[collection === 'import' ? 'emptyContainer' : 'releaseTerminal']
	const terminalAddress = record?.orderLocations?.filter((each) => {
		return each.locationType === 'Empty Container Dropoff' || each.locationType === 'Empty Container Pickup'
	})?.[0]?.formattedAddress

	const InfoPill = ({ icon, content }) => (
		<div className='FlexRow BoldFont WhiteColor HalfPaddingLeftRight HalfMarginRight CursorPointer Pill HeaderPill'>
			{icon && <Icon path={icon} size={1} className='HalfMarginRight' />}
			{content}
		</div>
	)
	return (
		<>
			<div
				className='FlexRowSpace PSBackground'
				style={{
					padding: '12px 24px 12px 24px',
					borderRadius: '8px 8px 0px 0px',
				}}
			>
				<div className='FlexRow'>
					<div className='FlexColumn HalfMarginRight'>
						<p className='BoldFont FontSize16 WhiteColor'>{`${PagesEnum[collection]?.initial} - ${orderNumber}`}</p>
						<p className='WhiteColor QuarterPaddingTop'>{businessCode}</p>
						<p className='WhiteColor BoldFont QuarterPaddingTop'>{refNumber && `Ref #: ${refNumber}`}</p>
					</div>
					{docNumber &&
						InfoPill({
							content: `${collection === 'import' ? 'BL #' : 'CRO #'} ${docNumber}`,
						})}

					{loadingDT &&
						InfoPill({
							icon: mdiCalendar,
							content: moment(loadingDT).format('DD-MMM-YYYY'),
						})}
				</div>
				<div className='FlexRow'>
					<Popover placement='bottom' content={LocationsTimeline(record)}>
						{InfoPill({ icon: mdiMapMarkerDistance, content: 'View Route' })}
					</Popover>
					{VehicleArranged(record)}
				</div>
			</div>
			{['import', 'export'].includes(collection) && (
				<div className='FlexRow' style={{ padding: '12px 24px 12px 24px' }}>
					<p>
						<span className='BoldFont'>{collection === 'import' ? 'Empty Container Terminal: ' : 'Container Release Terminal: '}</span>
						{terminalAddress || 'Not Provided'}
					</p>
					{(terminal?.returnDT || record?.freeDays) && <p className='HalfMarginLeft'> | </p>}
					{terminal?.returnDT && (
						<div className='FlexRow HalfMarginLeft'>
							<Icon path={mdiCalendar} size={1} className='QuarterMarginRight' />
							{moment(terminal?.returnDT).format('DD-MM-YYYY hh:mm A')}
						</div>
					)}
				</div>
			)}
			{['shifting', 'destuffing'].includes(orderCategory) && (
				<Tabs type='card' onChange={(key) => setSelectedTab(key)}>
					<Tabs.TabPane tab='Import Orders' key='ImportShortHaul' />
					<Tabs.TabPane tab='Bulk Orders' key='LongHaul' />
				</Tabs>
			)}
		</>
	)
}

export default OrderDetailsHeader

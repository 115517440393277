import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Menu, Tooltip } from 'antd'
import { currentCollection } from '../helpers/Functions'
import { PagesEnum } from '../helpers/Enums'
import Sprite from '../assets/icons/Sprite.svg'
import { useLocation } from 'react-router'
// import { withRouter } from 'react-router'

const { Sider } = Layout

const Sidebar = (props) => {
	const { pathname } = useLocation()
	const collection = useMemo(() => currentCollection(pathname), [pathname])

	const { collapseMenu, currentPage, setCurrentPage } = props

	useEffect(() => {
		setCurrentPage(collection)
	}, [collection]) // eslint-disable-line
	const navigate = useNavigate()
	return (
		<Sider collapsed={collapseMenu} width={68} collapsedWidth={0}>
			<div className='FullHeight FlexColumnSpace'>
				<Menu mode='inline' selectedKeys={currentPage}>
					{Object.values(PagesEnum).map((Item) => (
						<Menu.Item
							key={Item.key}
							icon={
								!collapseMenu && (
									<Tooltip placement='right' overlayClassName={'BoldFont'} color='#0066CC' title={Item.long} mouseEnterDelay={0}>
										<svg className='SidebarIcon'>
											<use href={Sprite + Item.icon} />
										</svg>
									</Tooltip>
								)
							}
							onClick={() => {
								setCurrentPage(Item.key)
								navigate(`/${Item.key}`)
							}}
						/>
					))}
				</Menu>
			</div>
		</Sider>
	)
}

export default Sidebar

import React, { useState, useEffect } from 'react'
import BridgelinxLogo from '../logo.png'

import { Form, Button } from 'antd'
import { RenderSchema } from '../helpers/FormRenderer'
import { LoginFormSchema } from '../helpers/FormSchemas'
import UnAuthorizedResult from '../components/utility/UnAuthorizedResult'
import AlertBanner from '../components/utility/AlertBanner'
import { useLogIn } from '../hooks/useAuth'

const Login = (props) => {
	const [loading, setLoading] = useState(false)
	const [authorized, setAuthorized] = useState(true)
	const [forgotPassword, setForgotPassword] = useState(false)
	const [alert, setAlert] = useState({ type: '', message: '' })
	const [form] = Form.useForm()
	const [submit, setSubmit] = useState(false)
	const { authorization } = useLogIn(setLoading, setAuthorized, setAlert, setSubmit)
	useEffect(() => {
		if (alert.type.length > 0) {
			setAlert({ type: '', message: '' })
		}
	}, [authorized, forgotPassword]) // eslint-disable-line
	useEffect(() => {
		if (alert.type.length > 0) {
			setAlert({ type: '', message: '' })
		}
	}, []) // eslint-disable-line
	return (
		<div className='FullWidthHeight DisplayFlex'>
			<div className='HalfWidth FullHeight PBackground'></div>
			<div className='HalfWidth FullHeight FlexColumnBox'>
				{authorized ? (
					<Form
						key='LoginForm'
						layout='vertical'
						className='HalfWidth'
						form={form}
						onFinish={(values) => authorization(forgotPassword, values.email + '@bridgelinxpk.com', values.password)}
					>
						<div className='FullWidth CenterAlign'>
							<img className='HalfWidth' src={BridgelinxLogo} alt='' style={{ marginBottom: 48 }} />
							{alert.type !== '' && AlertBanner(alert, setAlert)}
						</div>
						{RenderSchema(LoginFormSchema({ forgotPassword: forgotPassword, loading: loading }))}
						<div className='FullWidth CenterAlign HalfPaddingTop'>
							<Form.Item>
								<Button className='Button PrimaryButton' htmlType='submit' disabled={submit} loading={loading} block>
									{loading ? 'Please Wait' : !forgotPassword ? 'Login' : 'Confirm'}
								</Button>
							</Form.Item>
							{!forgotPassword ? (
								<Form.Item>
									<a
										className={'TertiaryButton ' + (loading ? 'DisableLink' : '')}
										href='/#'
										onClick={() => {
											setForgotPassword(true)
											setAlert({ type: '', message: '' })
											setSubmit(false)
										}}
									>
										Forgot Password?
									</a>
								</Form.Item>
							) : (
								<Form.Item>
									<a
										className={'TertiaryButton ' + (loading ? 'DisableLink' : '')}
										href='/#'
										onClick={() => {
											setForgotPassword(false)
											setAlert({ type: '', message: '' })
											setSubmit(false)
										}}
									>
										Try Logging in Again?
									</a>
								</Form.Item>
							)}
						</div>
					</Form>
				) : (
					<UnAuthorizedResult
						onClickFunction={() => {
							form.resetFields()
							setAuthorized(true)
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default Login

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Input, Select, Space, DatePicker } from 'antd'
import { Capitalize, check } from '../helpers/Functions'
import { CaretDownOutlined } from '@ant-design/icons'
import { GraphQLEnums, SegmentsEnum } from '../helpers/Enums'
import { useRecoilValue } from 'recoil'

const { RangePicker } = DatePicker
const AdvancedSearchBar = (props) => {
	const {
		data,
		setSearchData,
		collection,
		segment,
		keyword,
		setKeyword,
		filterProperty,
		setFilterProperty,
		selectedRecord,
		getOrderInRange,
		dateRange,
		setDateRange,
		resetState,
	} = props

	const searchOptions = [
		{ key: 'orderNumber', value: 'Order Number' },
		{ key: 'jobNumber', value: 'Job Number' },
		{ key: 'driverPhone', value: 'Driver Phone' },
		{ key: 'driverVehicleRegistration', value: 'Vehicle Number' },
		{ key: 'biltyNumber', value: 'Bilty Number' },
	]
	segment !== 'longHaul' &&
		searchOptions?.push(segment === 'import' ? { key: 'documentNumber', value: 'BL Number' } : { key: 'documentNumber', value: 'CRO Number' }, {
			key: 'containerNumber',
			value: 'Container Number',
		})
	const searchAtomData = useRecoilValue(SegmentsEnum[Capitalize(segment)]?.searchAtom)

	useEffect(() => {
		setKeyword('')
	}, [collection])

	useEffect(() => {
		if (selectedRecord) setKeyword('')
	}, [selectedRecord])

	useEffect(() => {
		setSearchData({ data: searchFields(), collection: collection })
	}, [data, keyword, filterProperty, dateRange])

	const searchFields = () => {
		if (keyword.length > 0 && !searchAtomData) {
			if (typeof filterProperty === 'object') {
				const AllPropertiesData = filterProperty.map((prop) => {
					if (typeof prop === 'string') {
						return data.filter((singleObj) => {
							if (check(singleObj[prop])) {
								return (singleObj[prop] + '').toLowerCase().includes(keyword.toLowerCase())
							}
						})
					} else if (typeof prop === 'object') {
						return data.filter((singleObj) => filterObject(singleObj, prop, 0))
					}
				})
				let FilteredData = []
				for (let i = 0; i < AllPropertiesData.length; i++) {
					FilteredData = FilteredData?.concat(AllPropertiesData[i])
				}
				const UniqueFilteredData = [...new Set(FilteredData)]
				return UniqueFilteredData
			} else {
				const FilteredData = data?.filter((singleObj) => {
					if (check(singleObj[filterProperty])) {
						return (singleObj[filterProperty] + '').toLowerCase().includes(keyword.toLowerCase())
					}
				})
				return FilteredData
			}
		} else {
			return data
		}
	}

	const SearchBarAddOn = () => (
		<Select
			className='SelectField'
			suffixIcon={<CaretDownOutlined color='#333333' />}
			defaultValue='orderNumber'
			onChange={(value) => setFilterProperty(value)}
		>
			{searchOptions?.map((option) => (
				<Select.Option key={option.key} value={option.key}>
					{option.value}
				</Select.Option>
			))}
		</Select>
	)

	const filterObject = (singleObj, prop, index) => {
		let flag = false
		if (singleObj[prop[index]] !== null && singleObj[prop[index]] !== undefined) {
			if (typeof singleObj[prop[index]] === 'object') {
				flag = filterObject(singleObj[prop[index]], prop, index + 1)
			} else if (typeof singleObj[prop[index]] === 'string' || typeof singleObj[prop[index]] === 'number') {
				return (singleObj[prop[index]] + '').toLowerCase().includes(keyword.toLowerCase())
			}
		}
		return flag
	}

	return (
		!selectedRecord && (
			<>
				<Input
					className={`HalfWidth HalfMargin ${!props.border ? `NoBorder` : `NoBorderLeftRight`}`}
					value={keyword}
					placeholder={props.placeholder}
					addonBefore={SearchBarAddOn()}
					onChange={(e) => setKeyword(e.target.value)}
				/>
				<div style={{ marginRight: '20px' }}>
					<Space direction='horizentol' size={12}>
						<RangePicker
							defaultValue={dateRange}
							onChange={(value) => {
								// console.log(value)
								setDateRange(value)
								resetState()
							}}
						/>
					</Space>
				</div>
			</>
		)
	)
}

export default AdvancedSearchBar

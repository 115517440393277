import { ApolloClient, InMemoryCache, split, HttpLink, from } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { Observable } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import firebase from 'firebase/compat/app'

const promiseToObservable = (promise) =>
	new Observable((subscriber) => {
		promise.then(
			(value) => {
				if (subscriber.closed) return
				subscriber.next(value)
				subscriber.complete()
			},
			(err) => subscriber.error(err)
		)
	})

const authLink = setContext(async (_, { headers }) => {
	const cashedToken = JSON.parse(localStorage.getItem('token'))?.token
	if (cashedToken) {
		return {
			headers: {
				...headers,
				authorization: `Bearer ${cashedToken}`,
			},
		}
	}
	return firebase
		.auth()
		.currentUser?.getIdToken()
		.then((token) => {
			localStorage.setItem('token', JSON.stringify({ token }))
			return {
				headers: {
					...headers,
					authorization: token ? `Bearer ${token}` : '',
				},
			}
		})
})

const retryLink = new RetryLink({
	delay: {
		initial: 300,
		max: Infinity,
	},
	attempts: {
		max: 3,
		retryIf: (error, _operation) => {
			// console.log(error)
			return true
		},
	},
})

const refreshToken = async (operation) => {
	const oldHeaders = operation.getContext().headers
	const token = await firebase.auth().currentUser?.getIdToken()
	// console.log(token)
	localStorage.setItem('token', JSON.stringify({ token }))
	operation.setContext({
		headers: {
			...oldHeaders,
			authorization: token,
		},
	})
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	if (graphQLErrors && graphQLErrors[0].message === 'Unauthenticated') {
		// console.log('hello world')
		return promiseToObservable(refreshToken(operation)).flatMap(() => forward(operation))

		// localStorage.setItem('token', JSON.stringify(null))
	}
})

const wsLink = new GraphQLWsLink(
	createClient({
		url: process.env.REACT_APP_BACKEND_WEB_SOCKET_URL,
		on: {
			connected: (e) => console.log('connected'),
			closed: (e) => console.log('closed'),
			opened: (e) => console.log('opened'),
			message: (e) => console.log('message', e),
			error: (e) => console.log('error'),
		},
	})
)
const httpLink = new HttpLink({
	uri: process.env.REACT_APP_BACKEND_URL,
})

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query)
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
	},
	wsLink,
	httpLink
)

export const client = new ApolloClient({
	link: from([errorLink, retryLink, authLink, splitLink]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					orders: {
						keyArgs: ['where'],
						merge(existing = [], incoming = []) {
							// console.log('incoming', incoming)
							// console.log('existing', existing)
							const merged = [...new Map([...existing, ...incoming].map((obj) => [JSON.stringify(obj), obj])).values()]
							// console.log('merged', merged)
							return merged
						},
					},
				},
			},
		},
	}),
})

import React, { useState, useMemo } from 'react'

import { Layout, Menu } from 'antd'
import Icon from '@mdi/react'
import { mdiFlaskEmptyRemove, mdiUnfoldMoreVertical } from '@mdi/js'
import { currentCollection } from '../helpers/Functions'
import SearchBar from '../components/SearchBar'

import { useRecoilValue } from 'recoil'
import { corporatesSelector } from '../recoil/selectors'

import { useFetch } from '../hooks/useGraphql'
import { useLocation } from 'react-router'

const { Sider } = Layout

const ClientSidebar = (props) => {
	const { pathname } = useLocation()
	const collection = useMemo(() => currentCollection(pathname), [pathname])
	const { selectedClient, setSelectedClient } = props
	const [searchData, setSearchData] = useState({ data: [], collection: '' })
	const [collapseMenu, setCollapseMenu] = useState(mdiFlaskEmptyRemove)

	const loading = useFetch({ type: 'Corporates', atomKey: 'corporate' })

	const clientData = useRecoilValue(corporatesSelector({ collection }))
	const FilterProperties = ['businessCode', 'businessName']
	// console.log(clientData)
	const getMenuItem = (Item) => (
		<Menu.Item
			key={Item._id}
			onClick={(e) => {
				setSelectedClient(Item)
			}}
			style={{ fontSize: 12 }}
		>
			<div className='FlexRowSpace'>
				{Item.businessCode} <span>{Item.count}</span>
			</div>
			{/* <div>Usman</div> */}
		</Menu.Item>
	)

	const MenuIcon = () => (
		<div
			className='ContentWidth FlexRowBox PBackground BorderRadiusCircle CursorPointer Circle24 SiderButton'
			onClick={() => setCollapseMenu((prevState) => !prevState)}
		>
			<Icon path={mdiUnfoldMoreVertical} size={1} className='WhiteColor' />
		</div>
	)

	return (
		<Sider collapsed={collapseMenu} collapsedWidth={24}>
			<SearchBar
				data={clientData}
				collection={collection}
				setSearchData={setSearchData}
				filterProperty={FilterProperties}
				placeholder='Search'
				hidePrefix={true}
				border={false}
			/>
			<MenuIcon />
			<div className={'NinetyTenHeight OverflowScroll ' + (collapseMenu ? 'Hidden' : '')}>
				{loading ? (
					<>
						<p>loading</p>
					</>
				) : (
					<Menu mode='inline' selectedKeys={[selectedClient?._id]}>
						{getMenuItem({
							_id: 'All Clients',
							businessCode: 'All Clients',
							orders: clientData?.map((Item) => Item.orders)?.flat(),
						})}
						{/* {clientData?.map(Item => getMenuItem(Item))} */}
						{searchData?.data?.length > 0 && searchData?.data?.map((Item) => getMenuItem(Item))}
					</Menu>
				)}
			</div>
		</Sider>
	)
}

export default ClientSidebar

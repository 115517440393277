import { gql } from '@apollo/client'

export const LOCATION_FIELDS = gql`
	fragment LocationFields on Location {
		_id
		formattedAddress
		additionalDetails
		coordinates {
			lat
			lng
		}
		Region {
			ParentRegion {
				name
				_id
			}
			name
			_id
		}
		type
		Region {
			_id
			name
		}
	}
`
export const CONTACT_FIELDS = gql`
	fragment ContactFields on Contacts {
		name
		phoneNumber
	}
`
export const USER_FIELDS = gql`
	fragment UserFields on User {
		_id
		firstName
		lastName
		phoneNumber
		email
		cnic
	}
`

export const DO_STATUS_FIELDS = gql`
	fragment DoStatusFields on DoStatus {
		_id
		status
		comment
		documents {
			payOrder
			underTakings
			blStamped
			loloReceipt
			goodsDeclaration
		}
	}
`

export const QUOTATION_FIELDS = gql`
	fragment QuotationFields on Quotation {
		_id
		JobId
		BrokerId
		SupplierId
		cost
		driver {
			name
			vehicleRegistrationNumber
			phoneNumber
		}
		approved
		slipped
		bidWon
	}
`

export const VENDOR_PAYMENT_FIELDS = gql`
	fragment VendorPaymentFields on vendorPayments {
		costBreakdown {
			amanat
			kantaCharges
			loloCharges
			offLoadingCharges
			placementCharges
			repairingCharges
			rotationalCharges
			beneficiary
			cost
			deductions
			detentions
			dropoff
			extraWeight
			inaam
			otherCharges
			supplyComments
		}
	}
`
export const SHIPMENT_INFO_FIELDS = gql`
	fragment ShipmentInfoFields on ShipmentInfo {
		_id
		freightCount
		freightType
		freightWeight
		estimatedWeight
		estimatedCount
	}
`
export const IMPORT_EXPORT_PREFERENCES_FIELDS = gql`
	fragment ImportExportPreferencesFields on SpecialRequest {
		movementType
		doIssuance
		numberFlatbeds
		otherRequests
		fastLane
		refNumber
	}
`
export const LONGHAUL_PREFERENCES_FIELDS = gql`
	fragment LongHaulPreferencesFields on LongHaulSpecialRequest {
		lotOrder
		numberVehicles
		otherRequests
		refNumber
	}
`
export const LANE_FIELDS = gql`
	fragment LaneFields on Lane {
		_id
		from {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
		to {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
	}
`
export const LANE_WITH_RATE_FIELDS = gql`
	fragment LaneRateFields on LaneWithLaneRate {
		_id
		from {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
		to {
			_id
			name
			ParentRegion {
				_id
				name
			}
		}
		LaneRate {
			_id
			Rate
			WeightRange
			createdAt
		}
	}
`
export const COMMENT_FIELDS = gql`
	${USER_FIELDS}
	fragment CommentFields on Comment {
		_id
		Author {
			...UserFields
		}
		comment
		createdAt
	}
`

export const SUPPLIER_FIELDS = gql`
	${USER_FIELDS}
	fragment SupplierFields on Supplier {
		_id
		isBlocked
		code
		isSuspended
		User {
			...UserFields
		}
		type
	}
`

export const BROKER_FIELDS = gql`
	${USER_FIELDS}
	fragment BrokerFields on Broker {
		_id
		User {
			...UserFields
		}
		type
	}
`
export const VEHICLE_FIELDS = gql`
	fragment VehicleFields on Vehicle {
		_id
		vehicleType
		vehicleSubType
		BrokerId
		SupplierId
		registrationNumber
	}
`
export const DRIVER_FIELDS = gql`
	fragment DriverFields on Driver {
		driverType
		name
		phoneNumber
		User {
			firstName
			lastName
			phoneNumber
		}
		name
		phoneNumber
	}
`
export const BILTY_FIELDS = gql`
	fragment BiltyFields on Bilty {
		_id
		biltyNumber
		biltyDocument
		kantaWeight
		kantaDocument
		initialKantaWeight
		initialKantaDocument
		halfDoneBiltyDocument
		documentNumber
		biltyCreatedAt
	}
`
export const JOURNEY_FIELDS = gql`
	fragment JourneyFields on Journey {
		_id
		steps
		DriverStatusHistories {
			_id
			status
			timestamp
			createdAt
			deletedAt
			DriverStatusJourneys {
				_id
				type
				createdAt
				deletedAt
				formattedAddress
				status
				comment
				CreatedBy {
					_id
					firstName
					lastName
				}
				resolvedComments
				timestamp
				coordinates {
					lat
					lng
				}
			}
		}
	}
`
export const CORPORATE_FIELDS = gql`
	fragment CorporateFields on Corporate {
		_id
		businessCode
		businessName
	}
`

export const CLIENT_FIELDS = gql`
	${USER_FIELDS}
	fragment ClientFields on Client {
		_id
		User {
			...UserFields
		}
	}
`
export const ORDER_LOCATION_FIELDS = gql`
	${LOCATION_FIELDS}
	${CONTACT_FIELDS}
	fragment OrderLocationFields on OrderLocation {
		_id
		locationType
		Location {
			...LocationFields
		}
		contacts {
			...ContactFields
		}
	}
`
export const CORPORATE_LOCATION_FIELDS = gql`
	${LOCATION_FIELDS}
	${CONTACT_FIELDS}
	fragment CorporateLocationFields on CorporateLocations {
		_id
		Location {
			...LocationFields
		}
		contacts {
			...ContactFields
		}
	}
`

export const JOB_SUBSCRIPTION_FIELDS = gql`
	${VEHICLE_FIELDS}
	${DRIVER_FIELDS}
	${BILTY_FIELDS}
	${JOURNEY_FIELDS}
	${LANE_FIELDS}
	fragment JobSubscriptionFields on Job {
		jobNumber
		_id
		status
		cost
		OrderDetails
		Vehicle {
			...VehicleFields
		}
		Driver {
			...DriverFields
		}
		bilty {
			...BiltyFields
		}
		Journeys {
			...JourneyFields
		}
		Lane {
			...LaneFields
		}
		SuperLane {
			...LaneFields
		}
	}
`

export const JOB_FIELDS = gql`
	${VEHICLE_FIELDS}
	${DRIVER_FIELDS}
	${BILTY_FIELDS}
	${JOURNEY_FIELDS}
	${LANE_FIELDS}
	fragment JobFields on Job {
		jobNumber
		_id
		status
		cost
		OrderDetails
		Vehicle {
			...VehicleFields
		}
		Driver {
			...DriverFields
		}
		bilty(options: $biltyOptions2) {
			...BiltyFields
		}
		Journeys {
			...JourneyFields
		}
		Lane {
			...LaneFields
		}
		SuperLane {
			...LaneFields
		}
	}
`
export const SUB_IMPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	${DO_STATUS_FIELDS}
	fragment SubImportFields on ChildImport {
		_id
		loadingDT
		subOrderNumber
		status
		freeDays
		containerNumber
		committedReturnDeadline
		numberOfUnits
		dimension
		freightType
		freightWeight
		eir {
			eirDocument
		}
		DoStatuses {
			...DoStatusFields
		}
		OrderLocations {
			...OrderLocationFields
		}
	}
`
export const SUB_EXPORT_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	fragment SubExportFields on ChildExport {
		_id
		finalizedRate
		loadingDT
		subOrderNumber
		status
		containerNumber
		dimension
		freightType
		freightWeight
		clientCommittedDeliveryDate
		OrderLocations {
			...OrderLocationFields
		}
	}
`
export const SUB_LONGHAUL_FIELDS = gql`
	${ORDER_LOCATION_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	fragment SubLongHaulFields on ChildLongHaul {
		_id
		loadingDT
		subOrderNumber
		status
		vehicleType
		vehicleSubType
		OrderLocations {
			...OrderLocationFields
		}
		shipmentInfo {
			...ShipmentInfoFields
		}
	}
`
export const MOVEMENT_SUBSCRIPTION_FIELDS = gql`
	${JOB_SUBSCRIPTION_FIELDS}
	${SUB_IMPORT_FIELDS}
	${SUB_EXPORT_FIELDS}
	${SUB_LONGHAUL_FIELDS}
	fragment MovementSubscriptionFields on Movement {
		ChildOrder {
			SubImport {
				...SubImportFields
			}
			SubExport {
				...SubExportFields
			}
			SubLongHaul {
				...SubLongHaulFields
			}
		}
		movementType
		subMovementType
		status
		count
		totalCount
		movedUnits
		partialMatch
		Job {
			...JobSubscriptionFields
		}
	}
`

export const MOVEMENT_FIELDS = gql`
	${JOB_FIELDS}
	${SUB_IMPORT_FIELDS}
	${SUB_EXPORT_FIELDS}
	${SUB_LONGHAUL_FIELDS}
	fragment MovementFields on Movement {
		ChildOrder {
			SubImport {
				...SubImportFields
			}
			SubExport {
				...SubExportFields
			}
			SubLongHaul {
				...SubLongHaulFields
			}
		}
		movementType
		subMovementType
		status
		count
		totalCount
		movedUnits
		partialMatch
		Job {
			...JobFields
		}
	}
`

export const PARENT_IMPORT_FIELDS = gql`
	${CONTACT_FIELDS}
	${DO_STATUS_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentImportFields on ParentImport {
		_id
		orderNumber
		status
		document
		documentNumber
		shippingLine
		clearingAgents {
			name
			phoneNumber
		}
		DoStatuses {
			...DoStatusFields
		}
		Comments {
			...CommentFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`
export const PARENT_EXPORT_FIELDS = gql`
	${CONTACT_FIELDS}
	${IMPORT_EXPORT_PREFERENCES_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentExportFields on ParentExport {
		_id
		document
		documentNumber
		shippingLine
		orderNumber
		status
		vesselCutoffDT
		clearingAgents {
			name
			phoneNumber
		}
		Comments {
			...CommentFields
		}
		preferences {
			...ImportExportPreferencesFields
		}
	}
`
export const PARENT_LONGHAUL_FIELDS = gql`
	${LONGHAUL_PREFERENCES_FIELDS}
	${SHIPMENT_INFO_FIELDS}
	${COMMENT_FIELDS}
	fragment ParentLongHaulFields on ParentLongHaul {
		_id
		orderNumber
		status
		expectedMovementDeadline
		Comments {
			...CommentFields
		}
		preferences {
			...LongHaulPreferencesFields
		}
		shipmentInfo {
			...ShipmentInfoFields
		}
	}
`

export const ORDER_SUBSCRIPTION_FIELDS = gql`
	${PARENT_IMPORT_FIELDS}
	${PARENT_EXPORT_FIELDS}
	${PARENT_LONGHAUL_FIELDS}
	${MOVEMENT_SUBSCRIPTION_FIELDS}
	${CLIENT_FIELDS}
	${CORPORATE_FIELDS}
	fragment OrderSubscriptionFields on Order {
		_id
		type
		createdAt
		businessCode
		clientEmails
		clientCCEmails
		emailThread {
			_id
			threadId
			threadSubject
		}
		VehicleInfos {
			_id
			quantity
			vehicleType
		}
		Client {
			...ClientFields
		}
		Corporate {
			...CorporateFields
		}
		ParentOrder {
			ParentImport {
				...ParentImportFields
			}
			ParentExport {
				...ParentExportFields
			}
			ParentLongHaul {
				...ParentLongHaulFields
			}
			Movements {
				...MovementSubscriptionFields
			}
		}
	}
`

export const ORDER_FIELDS = gql`
	${PARENT_IMPORT_FIELDS}
	${PARENT_EXPORT_FIELDS}
	${PARENT_LONGHAUL_FIELDS}
	${MOVEMENT_FIELDS}
	${CLIENT_FIELDS}
	${CORPORATE_FIELDS}
	fragment OrderFields on Order {
		_id
		type
		createdAt
		businessCode
		clientEmails
		clientCCEmails
		emailThread {
			_id
			threadId
			threadSubject
		}
		VehicleInfos {
			_id
			quantity
			vehicleType
		}
		Client {
			...ClientFields
		}
		Corporate {
			...CorporateFields
		}
		ParentOrder {
			ParentImport {
				...ParentImportFields
			}
			ParentExport {
				...ParentExportFields
			}
			ParentLongHaul {
				...ParentLongHaulFields
			}
			Movements {
				...MovementFields
			}
		}
	}
`

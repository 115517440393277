import { auth } from '../firebase/firebaseConfig'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userAtom } from '../recoil/atoms'

import { useLazyFetch } from './useGraphql'
import { useEffect } from 'react'
import { authSelector } from '../recoil/selectors'
import { GraphQLEnums } from '../helpers/Enums'
import { useNavigate, useLocation } from 'react-router-dom'

export const useLogIn = (setLoading = () => {}, setAuthorized, setAlert, setSubmit) => {
	const setUserAtom = useSetRecoilState(userAtom)
	// const [user, setUserAtom] = useRecoilState(userAtom)
	const [authenticateUser, loading] = useLazyFetch({ type: 'User', atomKey: 'user', storageKey: 'user' })
	const userAuth = useRecoilValue(authSelector())
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const currLocation = pathname.split('/')?.[1]
	// console.log(user)
	// useEffect(() => {
	// 	if (!loading && userAuth) {
	// 		if (userAuth?.authorized === false) {
	// 			setAuthorized(false)
	// 			setUserAtom(null)
	// 			localStorage.setItem('user', JSON.stringify(null))
	// 		}
	// 		setLoading(false)
	// 	}
	// }, [userAuth]) // eslint-disable-line

	useEffect(() => {
		// console.log(userAuth, loading)
		if (!loading && userAuth) {
			if (userAuth?.user === 'no user') {
				setAlert({
					type: 'error',
					message: 'No user record for this email address.',
				})
				setLoading(false)
				localStorage.setItem('user', JSON.stringify(null))
			} else if (userAuth?.authorized === false) {
				setLoading(false)
				setAuthorized(false)
				setUserAtom(null)
				localStorage.setItem('user', JSON.stringify(null))
			} else if (userAuth?.authorized === true && currLocation === 'login') {
				setLoading(false)
				navigate('/import')
			}
		}
	}, [userAuth, loading]) // eslint-disable-line

	const signInWithEmailAndPassword = async (email, password) => {
		const userCredential = await auth.signInWithEmailAndPassword(email, password)

		if (userCredential) {
			// const token = await auth.currentUser.getIdToken()
			// const user = await auth.currentUser
			const token = await auth.currentUser?.getIdToken()
			// console.log('You are an authorized user.', { token })
			localStorage.setItem('token', JSON.stringify({ token }))
			// localStorage.setItem('user', JSON.stringify({ user }))
			// sessionStorage.setItem('user', JSON.stringify({ user }))
			const processedVariables = GraphQLEnums.User.fetch.getVariables({ _id: userCredential?.user?.uid })

			authenticateUser({ variables: processedVariables })
		}
	}

	const resetPassword = async (email) => {
		await auth.sendPasswordResetEmail(email)
		setLoading(false)
		return 'success'
	}

	const logout = () => {
		// console.log('I cliked')
		if (userAuth) {
			auth.signOut()
			sessionStorage.removeItem('user')
			localStorage.removeItem('user')
			sessionStorage.removeItem('token')
			localStorage.removeItem('token')
			setUserAtom(null)
			navigate('/login')
		}
	}

	const authorization = async (forgotPassword, email, password) => {
		setLoading(true)
		try {
			if (!forgotPassword) {
				await signInWithEmailAndPassword(email, password)
			} else {
				const result = await resetPassword(email)
				if (result === 'success') {
					setAlert({
						type: 'success',
						message: 'Reset link has been sent to your email.',
					})
					setSubmit(true)
				}
			}
		} catch (error) {
			setLoading(false)
			if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password') {
				setAlert({
					type: 'error',
					message: 'Email address or password is incorrect.',
				})
			} else if (error.code === 'auth/user-not-found') {
				setAlert({
					type: 'error',
					message: 'No user record for this email address.',
				})
			} else {
				setAlert({
					type: 'error',
					message: 'Please check your internet connection.',
				})
			}
		}
	}

	return { authorization, logout }
}
